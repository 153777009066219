@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

a {
    text-decoration: none;
}

.scheduler {
    font-family: "Poppins", sans-serif;
}

.scheduler td {
    vertical-align: top;
}

/* .scheduler-view>div>div {
    height: auto !important;
} */

.resource-view {
    border-radius: 10px 0 0 0;
}

/* .resource-view>div{width: 100%!important;} */
/* .resource-view>div,
.scheduler-view>div {
    height: auto !important;
} */

table.resource-table thead tr,
table.scheduler-bg-table thead tr {
    height: auto !important;
}

table.resource-table thead tr th,
table.scheduler-bg-table thead tr th {
    border-right: 1px solid #CECECE;
    border-bottom: 1px solid #CECECE;
    background: transparent linear-gradient(180deg, #0662B9 0%, #0256A5 100%) 0% 0% no-repeat padding-box;
    color: #fff !important;
    font-size: 14px;
    line-height: 22px;
    padding: 20px;
}
table.scheduler-bg-table thead tr th.header3-text a{
    color: #fff !important;
}

/* table.scheduler-bg-table td{min-width: 283px!important;} */
table.resource-table tr th:last-child {
    border-radius: 10px 0 0 0;
}

table.resource-table tr td:last-child {
    vertical-align: middle;
    padding: 20px 20px;
    text-align: center;
}

/* table.resource-table tr td:last-child{height: 100px !important; vertical-align: middle; padding: 20px 20px; text-align: center; min-width: 283px !important;} */
/* table.resource-table tbody tr:nth-child(odd) {
    height: 59px !important;
} */

/* table.resource-table tbody tr:not(:nth-last-child(-n+3)) td.contractor-class {
    height: 59px !important;
    padding: 10px 20px;
} */


/* table.resource-table tbody tr:nth-last-child(1),
table.resource-table tbody tr:nth-last-child(2),
table.resource-table tbody tr:nth-last-child(3) {
    height: 350px !important;
} */

/* table.scheduler-content-table tr td .event-container {
    height: 100% !important;
    min-height: 350px !important;
    overflow: hidden;
} */

/* table.resource-table tr,
table.scheduler-bg-table tr,
table.scheduler-table tr {
    height: 350px !important;
} */


/* .event-container, table.scheduler-bg-table td{
    min-height: 59px;
} */
/* .scheduler-content-table tbody tr:not(:nth-last-child(-n+3)) .event-container.contractor-class{
    height: 59px!important;
} */
/* .scheduler-content-table tbody tr .contractor-class{
    height: 59px!important;
} */
.source-list {
    margin-right: 15px;
    background: #FFFFFF;
    -webkit-box-shadow: 0px 4px 4px #0000001A;
    box-shadow: 0px 4px 4px #0000001A;
    border-radius: 10px;
    padding: 20px 10px;
    margin-top: 15px;
    overflow: hidden;
}

.sourcebox {
    border-bottom: 1px solid #dadada;
    margin: 0 0 20px;
    padding: 0 0 20px;
}

.sourcebox:last-child {
    border-bottom: none;
    margin: 0;
    padding: 0;
}

.sourcebox h4 {
    font-size: 16px;
    line-height: 21px;
    color: #17243E;
    margin: 0 0 20px;
    font-weight: 400;
}

.sourcebox .sourcebox-listing ul {
    display: flex;
    padding-left: 0;
    margin: 0;
    gap: 5px;
    flex-wrap: wrap;
    padding-right: 15px;
}

.sourcebox .sourcebox-listing ul li {
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: 600;
    font-size: 10px;
    color: #01101D;
    border-radius: 5px;
    font-weight: 500;
    width: calc(50% - 2.5px);
    border: 1px solid #ddd;
    text-transform: uppercase;
}

.sourcebox .sourcebox-listing ul li img {
    max-width: 25px;
    margin: 0 5px 0 0;
}


/* table.scheduler-bg-table tbody tr td {width: 283px !important; min-width: 283px !important;} */
/* .timeline-event{width: 283px !important; overflow-x: hidden; overflow-y: auto; height: 100%;} */
.timeline-event {
    text-decoration: none;
    padding: 30px 0 10px;
}

.timeline-event:not(:last-child) {
    margin-bottom: 5px;
}

.event-container .round-all.event-item {
    padding-right: 0 !important;
    border-radius: 0;
}

.event-container .round-all.event-item .row {
    margin: 0 0;
}

.event-container .round-all.event-item .text-primary {
    font-size: 12px;
    line-height: 16px;
    color: #17243E !important;
    text-align: center;
    margin-bottom: 5px;
    padding: 0 10px;
    text-align: center;
    word-break: break-all;
    white-space: normal;
    font-weight: 600;
}

.event-container .round-all.event-item .row.crew-members,
.event-container .round-all.event-item .row.assets {
    padding: 0px 5px;
    margin: 0;
}

.event-container .round-all.event-item .row.crew-members>[class*=col],
.event-container .round-all.event-item .row.assets>[class*=col] {
    width: 50%;
    padding: 0px 5px 10px;
}

.event-container .round-all.event-item .row.crew-members>[class*=col] div,
.event-container .round-all.event-item .row.assets>[class*=col] div {
    margin: 0 0 3px !important;
    border-radius: 5px;
    padding: 3px 3px 3px 3px;
    position: relative;
    font-size: 10px;
    min-height: 31px;
    color: #000;
    line-height: 12px;
    /* word-break: break-all; */
    white-space: normal;
    height: 100%;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
}
.event-container .round-all.event-item .row.crew-members>[class*=col] div span br,
.event-container .round-all.event-item .row.assets>[class*=col] div span br{
    display: none;
}
.event-container .round-all.event-item .row.crew-members>[class*=col] div span,
.event-container .round-all.event-item .row.assets>[class*=col] div span{
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
}
.event-container .round-all.event-item .row.crew-members>[class*=col] div span span,
.event-container .round-all.event-item .row.assets>[class*=col] div span span{
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
}
.event-container .round-all.event-item .row.assets>[class*=col] div p{
    margin-bottom: 0;
    overflow: hidden;
}
.event-container .round-all.event-item .row.assets>[class*=col] div p small{
    font-weight: 600;
}

.event-container .round-all.event-item .row.crew-members>[class*=col] div img,
.event-container .round-all.event-item .row.assets>[class*=col] div img {
    max-width: 23px !important;
    max-height: 20px !important;
    border-radius: 0 !important;
    margin-right: 5px;
}
.round-all.event-item .row:not(:last-child) {
    margin-bottom: 15px;
}

.timeline-event .event-resizer.event-start-resizer,
.timeline-event .event-resizer.event-end-resizer {
    display: none;
}

.crew-modal .modal-header {
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.crew-modal .modal-header .modal-title {
    font-size: 30px;
    font-weight: 600;
    color: #202020;
}

.crew-modal .form-group {
    margin-bottom: 30px;
}

.crew-modal .form-group select,
.crew-modal .form-group input.form-control{
    height: 58px;
}
.crew-modal .form-group textarea.form-control{
    height: 80px;
}
.crew-modal .form-group select:focus,
.crew-modal .form-group input:focus {
    box-shadow: none;
}

.crew-modal .form-group .custom-react-select input {
    height: 44px;
    color: var(--bs-body-color);
}

.crew-modal .form-group .custom-react-select>div>div>div:first-child {
    color: var(--bs-body-color);
    opacity: 0.8;
}

.crew-modal .form-group .custom-react-select>span+div {
    box-shadow: none !important;
    border-radius: 12px;
}
.crew-modal .form-group .custom-react-select>span+div+div{
    z-index: 3;
}
.crew-modal .form-group .custom-react-select>span+div>div {
    overflow: auto;
    max-height: 100px;
}

.crew-modal .form-group .css-13cymwt-control {
    border-radius: 12px;
    background-color:transparent;
    border: 0;
    border: var(--bs-border-width) solid var(--bs-border-color);
    color: var(--bs-body-color);
}

.crew-modal .modal-body {
    padding-bottom: 30px;
}

.crew-modal h3 {
    color: #202020;
    font-size: 30px;
    margin-bottom: 25px;
    font-weight: bold;
}

.crew-modal .modal-footer {
    border: 0;
    padding-top: 0;
    padding-bottom: 30px;
}

.crew-modal .modal-footer .btn {
    min-width: 160px;
}

.crew-modal .modal-footer .btn-outline {
    border-radius: 0.875rem;
    height: 60px;
    justify-content: center;
}

.btn-fill.add-job-btn {
    height: 38px;
    border-radius: 4px;
}

.ant-row {
    background-color: #F9F9F9;
    z-index: 0;
}

table.scheduler-bg-table thead tr th.current-date {
    background: rgb(212, 2, 0);
    background: -moz-linear-gradient(180deg, rgba(212, 2, 0, 1) 0%, rgba(255, 58, 57, 1) 35%);
    background: -webkit-linear-gradient(180deg, rgba(212, 2, 0, 1) 0%, rgba(255, 58, 57, 1) 35%);
    background: linear-gradient(180deg, rgba(212, 2, 0, 1) 0%, rgba(255, 58, 57, 1) 35%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d40200", endColorstr="#ff3a39", GradientType=1);
}

.sourcebox-filter-wrap {
    display: flex;
    justify-content: space-between;
    padding: 5px 0 15px 0px;
    position: relative;
}

.sourcebox-filter {
    display: flex;
}

.sourcebox-filter .dropdown {
    position: initial;
}

.sourcebox-filter .dropdown .dropdown-menu {
    padding: 15px;
    left: 0;
}

.button--filter .btn.btn-fill {
    height: 38px;
    border-radius: 6px;
}

.sourcebox-listing {
    position: relative;
    z-index: 1;
    width: 100%;
}

.crew-members-sourcebox .rc-scrollbars-view {
    padding-right: 0 !important;
}

.sourcebox-listing>div>div>ul {
    width: 100% !important;
}

.sourcebox-filter .dropdown ul li {
    padding: 0;
}

.sourcebox-filter .dropdown>a.filter-icon::after {
    display: none;
}

.filter--form .form-group .custom-react-select>div {
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}

.sourcebox-filter .dropdown ul li a {
    font-size: 14px;
}

.sourcebox .sourcebox-filter-wrap h4 {
    margin-bottom: 0;
}

.sourcebox .sourcebox-filter-wrap .search-icon {
    margin-right: 15px;
}

.source-list h3 {
    font-size: 22px;
    color: #0662B9;
    font-weight: 700;
    margin-bottom: 0;
}

.crew-members-sourcebox .rc-scrollbars-view {
    min-height: 250px;
    max-height: 250px;
    overflow: auto;
}

.iron-members-sourcebox .rc-scrollbars-view {
    min-height: 200px;
    max-height: 200px;
    overflow: auto;
}

.shop-members-sourcebox .rc-scrollbars-view {
    min-height: 200px;
    max-height: 200px;
    overflow: auto;
}

.crew-members-sourcebox .rc-scrollbars-track,
.iron-members-sourcebox .rc-scrollbars-track,
.shop-members-sourcebox .rc-scrollbars-track {
    background-color: #F9F9F9;
    width: 12px !important;
    border-radius: 100px !important;
}

.crew-members-sourcebox .rc-scrollbars-track-h,
.iron-members-sourcebox .rc-scrollbars-track-h,
.shop-members-sourcebox .rc-scrollbars-track-h {
    display: none;
}

.search-filter-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
    right: -200%;
    transition: 0.4s all;
}

.search-filter-wrap input {
    height: 34px;
    box-shadow: none;
    font-size: 14px;
}

.search-filter-wrap input:focus {
    box-shadow: none;
}

.search-filter-wrap.show {
    right: 0;
}

.form-group-inner a {
    width: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-row .ant-col-20 {
    padding: 0 15px;
    transition: 0.4s all;
}

.source-list-header {
    margin-bottom: 20px;
}

.ant-row .ant-col-4 {
    transition: 0.4s all;
}

.side-menu-hide .ant-row .ant-col-20 {
    width: 100%;
    transition: 0.4s all;
}

.side-menu-hide .ant-row .ant-col-4 {
    width: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s all;
}

.calendar-header {
    padding: 19px 0;
    background-color: #fff;
    box-shadow: 0px 3px 3px #0000004D;
}

.calendar-header .calendar-container {
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.calendar-header .calendar-container .select-date {
    padding-left: 128px;
    flex: 0 0 auto;
    /* margin-left: 10px; */
}

.calendar-header .calendar-container .select-date .form-floating {
    width: 300px;
}

.calendar-header .calendar-container .select-date .form-floating input {
    border-radius: 100px;
    background-color: #EFEFEF;
    padding-left: 60px;
    padding-right: 30px;
    border: 0;
    color: #929191;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-image: url(../images/arrow-gray-down.svg);
    background-repeat: no-repeat;
    background-size: 15px 8px;
    background-position: right 15px center;
}

.calendar-header .calendar-container .select-date .form-floating input:focus {
    box-shadow: none;
}

.calendar-header .calendar-container .select-date .form-floating input::-webkit-inner-spin-button,
.calendar-header .calendar-container .select-date .form-floating input::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.calendar-header .calendar-container .select-date .form-floating>.form-control:not(:placeholder-shown)~label {
    transform: scale(.85) translateY(-.5rem) translateX(60px);
    color: #000;
    font-weight: 700;
}

.calendar-header .calendar-container .select-date .form-floating .data-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 22px;
    z-index: 1;
}

.calendar-header .calendar-container .select-date .form-floating label::after {
    background-color: #EFEFEF;
}

.night-shift {
    background-color: #000;
}

.event-container .night-shift .round-all.event-item .text-primary {
    color: #fff !important;
}

.calendar-search {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    max-width: 480px;
    /* margin-left: 20px; */
}

.calendar-search .select-member {
    flex: 1;
    max-width: 136px;
}

.calendar-search .search-form-group {
    flex: 1;
}

.search-btn {
    background-color: transparent;
    padding: 0;
    border: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 27px;
}
.search-btn img{
    transform: rotate(270deg);
}

.calendar-search input,
.calendar-search select {
    border: 0;
    height: 58px;
    background-color: #EFEFEF;
    padding-left: 30px;
    color: #929191;
    font-weight: 500;
}

.calendar-search select:focus,
.calendar-search input:focus {
    box-shadow: none;
    background-color: #EFEFEF;
}

.calendar-search select {
    border-radius: 100px 0 0 100px;
    background-image: url(../images/arrow-gray-down.svg);
    background-repeat: no-repeat;
    background-size: 15px 8px;
    background-position: right 15px center;
    font-size: 15px;
}

.calendar-search input {
    border-radius: 0 100px 100px 0;
    padding-right: 50px;
}

.search-form-group {
    position: relative;
}

.search-form-group::before {
    background-color: #B1B1B1;
    width: 1px;
    height: 40px;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.75;
}

.header-button ul li {
    padding-right: 25px;
    margin-right: 25px;
    border-right: solid 1px #E9E7E7;
}

.header-button a {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    transition: 0.4s all;
}

.header-button a:hover {
    background-color: #0662B9;
}

.header-button a:hover img {
    filter: brightness(0) invert(1);
}

.calendar-header-right {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
}
/*
.resource-table tbody tr:nth-child(even) td {
    height: 350px !important;
} */

.ant-col-20 .sidebar-toggle-btn.fixed-btn {
    padding: 10px 15px;
    position: fixed;
    right: 0;
    top: 136px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    border-radius: 10px 0 0 10px;
    display: flex;
    z-index: 9;
    background-color: #fff;
    display: none;
}

.ant-col-20 .sidebar-toggle-btn.fixed-btn img {
    transform: rotate(180deg);
}

.side-menu-hide .ant-col-20 .sidebar-toggle-btn.fixed-btn {
    display: flex;
}

.calendar-header-right .header-profile-inner .dropdown-toggle {
    padding-right: 24px;
}

.calendar-header-right .header-profile-inner .dropdown-toggle .profile-type {
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.event-container .round-all.event-item .row.assets {
    margin-top: 10px;
}

.day-shift {
    background-color: #fff;
    border: solid 1px #e7e7e7;
}

.full-screen-button {
    margin-right: 15px;
    display: flex;
    text-decoration: none;
    align-items: center;
    font-size: 14px;
    color: #000000;
}

.full-screen-button img {
    margin-right: 5px;
}

.header-right-col {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.calendar-full-screen .ant-row .ant-col-20 {
    width: 100%;
}

.calendar-full-screen .calendar-header {
    display: none;
}

.calendar-full-screen .ant-row .ant-col-4 {
    display: none;
}

.side-menu-hide.calendar-full-screen .sidebar-toggle-btn.fixed-btn {
    display: none;
}

.icon-text-container.prev-view-icon,
.icon-text-container.next-view-icon {
    padding: 2.5px 8px;
    background-color: #D8D8D8;
    border-radius: 100px;
    font-size: 12px;
    color: #4A4A4A;
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    width: 22px;
    height: 22px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-text-container.next-view-icon {
    margin-left: 15px;
}

.icon-text-container.prev-view-icon:hover,
.icon-text-container.next-view-icon:hover {
    color: #fff;
    background-color: #000;
}

.icon-text-container.prev-view-icon svg {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.scheduler-view {
    border-top-right-radius: 10px;
    overflow: hidden;
}

.resource-view {
    border-bottom-left-radius: 10px;
}

.scheduler-bg-table {
    padding-top: 15px !important;
}

.scheduler-view-inner {
    padding-right: 0 !important;
}

.month-view .ant-row .ant-col-4 {
    display: none;
}

.month-view .ant-row .ant-col-20 {
    width: 100%;
}

/* .month-view .calendar-header {
    display: none;
} */

.scheduler-view--inr,
.side-view-inner {
    max-height: calc(100vh - 262px) !important;
}

.calendar-full-screen .scheduler-view--inr,
.calendar-full-screen .side-view-inner {
    max-height: calc(100vh - 145px) !important;
}

.modal-xxl {
    max-width: 800px;
}

.add-job-group {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: solid 1px #ccc;
}

.add-job-group:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}

.add-job-group h4 {
    font-size: 20px;
    color: #5c5c5c;
    font-weight: 700;
    margin-bottom: 15px;
}

.checkbox-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.checkbox-row>div {
    padding-bottom: 15px;
}

.custom-checkbox {
    padding: 0 15px;
    flex: 1;
    margin: 0;
}

.custom-checkbox input {
    display: none;
}

.custom-checkbox label {
    padding: 0px 15px 0px 45px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 12px;
    background-color: #F8F8F8;
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-checkbox label::before {
    position: absolute;
    left: 15px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: solid 1px #ccc;
    content: '';
    top: 50%;
    transform: translateY(-50%);
}

.custom-checkbox label::after {
    position: absolute;
    left: 15px;
    width: 20px;
    height: 20px;
    content: '';
    top: 50%;
    transform: translateY(-50%) scale(0);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.4s all;
}

.custom-checkbox input:checked+label {
    background-color: #0256a513;
    border-color: #0256a5;
    color: #0256a5;
    font-weight: 600;
}

.custom-checkbox input:checked+label::before {
    background-color: #0256a5;
    border-color: #0256a5;
}

.custom-checkbox input:checked+label::after {
    transform: translateY(-50%) scale(1);
}

/* .crew-modal .add-job-group .form-floating>label::after {
    background-color: #F8F8F8;
} */

.calendar--filter {
    margin: 15px 15px 0 15px;
    top: 0;
}

.calendar--filter.filter-box .accordion-body {
    border: 0;
    box-shadow: 0px 4px 4px #0000000A;
    border: 1px solid #DBDBDB66;
}

.calendar--filter .accordion-body .row {
    align-items: center;
}

.calendar--filter .accordion-body .row>div {
    width: 20%;
}

.subcontractors-radios {
    display: flex;
    align-items: center;
}

.subcontractors-group {
    display: flex;
    align-items: center;
}

.subcontractors-group>label {
    color: #202020;
    font-size: 18px;
    font-weight: bold;
}
.crew-modal .subcontractors-group>label {
    color: var(--bs-body-color);
    opacity: 0.8;
    font-weight: 500;
    font-size: 16px;
}
.crew-modal .subcontractors-group{
    flex-wrap: wrap;
}
.crew-modal .subcontractors-group>label{
    width: 100%;
    margin-bottom: 8px;
}
.subcontractors-group .form-check {
    margin-left: 20px;
}
.crew-modal .subcontractors-group .form-check:first-child{
    margin-left: 0;
}
.job-details-popup .add-job-group.day-shift{
    padding: 15px 15px 5px 15px;
    border-bottom: solid 1px #e7e7e7;
}
.job-details-popup .add-job-group.night-shift{
    padding: 15px 15px 5px 15px;
    border-bottom: solid 1px #e7e7e7;
}
.job-details-popup .add-job-group.night-shift h4{
    color: #fff;
}
.job-details-popup h3{
    color: #202020;
    font-size: 30px;
    margin-bottom: 25px;
    font-weight: bold;
}
.job-details-popup .row{
    margin: 0 -5px;
}
.job-details-popup .row>div{
    width: 33.33%;
    padding: 0 5px 10px 5px;
}
.job-details-popup span{
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: 600;
    font-size: 13px;
    color: #01101D;
    border-radius: 5px;
    font-weight: 500;
}
.job-details-popup  span img {
    max-width: 25px;
    margin: 0 10px 0 0;
}
.job-details-popup .assets{
    margin-top: 15px;
}
.crew-modal#notesModal .modal-body{
    padding-bottom: 1rem;
}
.notes-list .rc-scrollbars-view, .notes-list .rc-scrollbars-container{
    max-height: calc(100vh - (5.5rem + 261px))!important;
}
/* .month-view .filter-box.calendar--filter{
    display: none;
} */
.month-view .scheduler-view--inr, .month-view .side-view-inner{
    max-height: calc(100vh - 162px) !important;
}
@media(max-width:1599px) {
    .calendar-header .calendar-container .select-date {
        padding-left: 30px;
    }

    .header-button a {
        width: 45px;
        height: 45px;
    }

    .calendar-header-right .header-profile-inner .dropdown-toggle figure {
        width: 45px;
        height: 45px;
    }

    .calendar-header-right .profile-info {
        padding-left: 15px;
    }

    .calendar-header-right .profile-info .profile-name,
    .calendar-header-right .header-profile-inner .dropdown-toggle .profile-type {
        font-size: 14px;
    }

    .header-button ul li {
        padding-right: 15px;
        margin-right: 15px;
    }

}
@media(max-width:1499px) {
    .calendar--filter .accordion-body .row>div{
        width: 25%;
    }
}
@media(max-width:1399px) {
    .calendar-header .calendar-container .select-date .form-floating {
        width: 200px;
    }

    .header-button a {
        width: 30px;
        height: 30px;
    }

    .header-button a img {
        width: 20px;
    }
    /* .ant-col-4{
        width: 20%;
    }
    .ant-col-20{
        width: 80%;
    } */
}

@media(max-width:1199px) {
    .calendar-header-right .profile-info {
        display: none;
    }

    .calendar-search input,
    .calendar-search select {
        height: 48px;
    }

    .calendar-header .calendar-container .select-date .form-floating input {
        height: 48px;
        min-height: 48px;
        padding-left: 50px;
    }
    .calendar-header .calendar-container .select-date label{
        padding-left: 50px;

    }

    .calendar-header .calendar-container .select-date .form-floating .data-icon {
        width: 20px;
        display: flex;
    }

    .calendar-header .calendar-container .select-date .form-floating>.form-control:not(:placeholder-shown)~label {
        transform: scale(.85) translateY(-.5rem) translateX(45px);
    }

    .calendar-header-right .header-profile-inner .dropdown-toggle figure {
        width: 30px;
        height: 30px;
        border-radius: 6px;
    }

    .ant-row .ant-col-20 {
        width: 100%;
    }

    .ant-row .ant-col-4 {
        width: 100%;
        display: none;
    }

    /* table.scheduler-bg-table th{
        white-space: nowrap;
    } */
    .side-view-inner {
        width: 110px !important;
    }
    .calendar--filter .accordion-body .row>div{
        width: 33.33%;
    }
    .subcontractors-group{
        flex-wrap: wrap;
    }
    .subcontractors-group>label{
        width: 100%;
        flex: 0 0 auto;
        margin-bottom: 5px;
    }
    .subcontractors-group .form-check:first-child{
        margin: 0;
    }
}

@media(max-width:991px) {
    .calendar-header .calendar-container .logo {
        order: 1;
        flex: 0 0 auto;
        width: 50%;
    }

    .calendar-header .calendar-container .logo img {
        height: 50px;
        width: auto;
    }

    .calendar-header .calendar-container .select-date {
        width: 50%;
        padding-left: 0;
        order: 3;
        max-width: 100%;
        padding-top: 15px;
    }

    .calendar-search {
        width: 50%;
        order: 4;
        flex: 0 0 auto;
        margin-left: 0;
        padding-left: 15px;
        padding-top: 15px;
    }

    .calendar-header-right {
        flex: 1;
        order: 2;
    }

    .calendar-header .calendar-container .select-date .form-floating {
        width: 100%;
    }

    .calendar-search input,
    .calendar-search select {
        font-size: 14px;
    }

    .ant-radio-button-wrapper {
        padding: 0 !important;
        font-size: 10px !important;
    }

    .full-screen-button {
        font-size: 10px;
    }

    .icon-text-container.next-view-icon {
        margin-left: 10px;
    }

    .icon-text-container.prev-view-icon,
    .icon-text-container.next-view-icon {
        font-size: 10px;
    }
}

@media(max-width:767px) {
    .calendar-header .calendar-container .select-date {
        width: 100%;
    }

    .calendar-search {
        width: 100%;
        padding-left: 0;
        max-width: 100%;
    }

    .calendar-search select {
        padding-left: 15px;
    }

    .calendar-search input {
        padding-left: 15px;
        padding-right: 30px;
    }

    .search-btn {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 10px;
    }

    .header-button ul li {
        padding-right: 8px;
        margin-right: 8px;
    }

    .calendar-header .calendar-container .logo {
        width: auto;
    }

    .header-profile-inner .dropdown-toggle::after {
        width: 13px;
    }

    .calendar-header-right .header-profile-inner .dropdown-toggle {
        padding-right: 16px;
    }

    .calendar-header {
        padding: 10px 0;
    }

    .crew-modal .modal-footer .btn {
        min-width: 130px;
    }

    .full-screen-button {
        font-size: 10px;
        font-size: 0;
    }

    .icon-text-container.next-view-icon {
        margin-left: 10px;
    }

    .icon-text-container.prev-view-icon .icon-text,
    .icon-text-container.next-view-icon .icon-text {
        font-size: 0;
    }

    table.resource-table thead tr th,
    table.scheduler-bg-table thead tr th {
        font-size: 12px;
        padding: 10px;
    }

    .icon-text-container.prev-view-icon svg {
        margin: 0 !important;
    }

    .icon-text-container.next-view-icon i {
        margin: 0 !important;
    }

    .checkbox-row {
        flex-wrap: wrap;
    }

    .checkbox-row>div {
        width: 33.33%;
        flex: 0 0 auto;
    }
    .calendar--filter .accordion-body .row>div{
        width: 50%;
    }
}

@media(max-width:576px) {
    .checkbox-row>div {
        width: 50%;
        flex: 0 0 auto;
    }
    .calendar--filter .accordion-body .row>div{
        width: 100%;
    }
    .notes-list .rc-scrollbars-view, .notes-list .rc-scrollbars-container{
        max-height: calc(100vh - (3.5rem + 261px))!important;
    }
    .notes-body p, .notes-footer span{
        font-size: 13px;
    }
}



.crew-members>[class*=col] span , .assets>[class*=col] span{
    position: relative;
}
.event-container .box-remove-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(0);
    right: 7px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #c62826;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    z-index: 1;
}
.event-container .box-remove-btn img{
    width: 100%;
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    max-width: 100% !important;
    max-height: 100% !important;
    margin-right: 0 !important;
}
.event-container .crew-members>[class*=col] div:hover .box-remove-btn ,.event-container .assets>[class*=col] div:hover .box-remove-btn {
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%) scale(1);
}




.sourcebox .sourcebox-listing ul li span{
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
}
/* .timeline-event.night-shift{
    position: relative;
} */
/* .timeline-event.night-shift .event-item{
    position: relative;
} */
.timeline-event .event-item .shifts-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    width: auto;
    height: auto;
}
.timeline-event .event-item .shifts-icon figure{
    margin: 0 auto;
    width: 21px;
    height: 21px;
}
.timeline-event .event-item .shifts-icon figure img{
    width: 100%;
    height: 100%;
}
.timeline-event .event-item .shifts-icon span{
    color: #000;
    font-size: 10px;
    font-weight: 500;
}
.timeline-event.night-shift .event-item .shifts-icon span{
    color: #fff;
}
.filter-box.calendar--filter{
    z-index: 9;
}

.crew-modal .modal-body{
    padding: 38px 47px 55px;
}
.crew-modal .btn-close{
    padding: 0;
    position: absolute;
    right: 28px;
    top: 22px;
    height: auto;
    width: auto;
    background: none;
    text-align: right;
    color: #000000;
    font-size: 14px;
    opacity: 1;
    z-index: 1;
}
.crew-modal h3{
    font-size: 34px;
    font-weight: 500;
}



.employee-checkbox-row .custom-checkbox{
    padding: 0 15px ;
}

.calendar-header .calendar-container .select-date .form-floating input{
    height: 50px;
    padding-top: 20px;
    font-size: 12px;
    font-weight: 500;
    color: #929191;
}
.calendar-header .calendar-container .select-date label {
    padding: 0;
    padding-left: 60px;
    font-size: 14px;
    font-weight: 700;
}
.view-modal .modal-body h3{
    display: flex;
    align-items: center;
}
.view-modal .modal-body .shifts-icon{
    margin-left: 20px;
}
.view-modal .modal-body .shifts-icon figure{
    margin-bottom: 0;
}
/* .view-modal .modal-body .shifts-icon{
    position: absolute;
    top: 5px;
    left: 10px;
} */
.month-view .round-all.event-item .row{
    margin-bottom: 5px !important;
}
.month-view .timeline-event .event-item .shifts-icon{
    width: auto;
    height: auto;
}
.timeline-event{
    padding: 45px 0 10px;
}
.calendar-view-page .menu-btn{
    /* padding-left: 128px; */
    /* flex: 0 0 auto; */
    outline: none;
    box-shadow: none;
    z-index: 9;
    margin-right: 25px;
}
.calendar-view-page .side-menu{
    left: -100%;
}
.calendar-view-page.side-menu-open .side-menu{
    left: 0;
}
.job-edit-btns{
    position: absolute;
    top: 5px;
    left: 10px;
    /* width: ; */
    display: flex;
    align-items: center;
}
.job-edit-btns button.btns{
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    transition: all 0.2s ease-in-out;
}
.job-edit-btns button.btns:hover{
    background-color: #0256A5;
    border-color: transparent;
}
.job-edit-btns button.btns img{
    transition: all 0.2s ease-in-out;
    filter: invert(1) brightness(0);
}
.job-edit-btns button.btns:not(:last-child){
    margin-right: 5px;
}
.job-edit-btns button.btns:hover img{
    filter: invert(1) brightness(5);
}
.scheduler-view--inr .rc-scrollbars-container , .side-view-inner .rc-scrollbars-container{
    max-height: calc(100vh - 262px) !important;
}
.scheduler-view--inr .rc-scrollbars-view , .side-view-inner .rc-scrollbars-view{
    max-height: calc(100vh - 262px) !important;
}
.month-view .scheduler-view--inr .rc-scrollbars-container , .month-view  .side-view-inner .rc-scrollbars-container{
    max-height: calc(100vh - 162px) !important;
}
.month-view .scheduler-view--inr .rc-scrollbars-view , .month-view  .side-view-inner .rc-scrollbars-view{
    max-height: calc(100vh - 162px) !important;
}
.view-modal .list-group-flush .list-group-item div ul li{
    width: auto;
    min-width: 155px;
}
.scheduler-view--inr::-webkit-scrollbar-track , .side-view-inner::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.scheduler-view--inr::-webkit-scrollbar , .side-view-inner::-webkit-scrollbar
{
	width: 7px;
    height: 8px;
	background-color: #F5F5F5;
}

.scheduler-view--inr::-webkit-scrollbar-thumb , .side-view-inner::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #0066a7;
}

.job-counter-list{
    margin-top: 15px;
}
.job-counter-list:not(:last-child){
    margin-right: 10px;
    padding-right: 10px;
    border-right: 2px solid #ddd;
}
.job-counter-list img{
    width: 15px;
    height: 15px;
}
.job-counter-list span{
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    padding-left: 10px;
}
.checkout-process-strip li .process-inner-wrap span button{
    border: none;
    padding: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
}
.checkout-process-strip li .process-inner-wrap span button:hover{
    border: none;
    background-color: transparent;
}
.checkout-process-strip li.incompleted p{
    color: #b00000;
}
.checkout-process-strip li.incompleted .process-inner-wrap{
    background-color: #b00000;
}
.timeline-event.draft-shift{
    background-color: #f8d7da;
}
.job-edit-btns .draft-text{
    display: none;
}
.timeline-event.draft-shift .job-edit-btns .draft-text{
    font-size: 10px;
    font-weight: 700;
    color: #d83b01;
    display: block;
}
.notes-footer span.action-btn{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0662b9;
    padding: 5px;
    background-color: #0662b9;
    cursor: pointer;
}
.notes-footer span.action-btn img{
    margin-right: 0;
    filter: invert(1) brightness(5);
}
.notes-footer span:last-child{
    margin-right: 0;
}
.edit-note-modal .react-confirm-alert-overlay{
    z-index: 9999;
}
.add-job-group .notes-list::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.add-job-group .notes-list::-webkit-scrollbar
{
	width: 7px;
    height: 8px;
	background-color: #F5F5F5;
}

.add-job-group .notes-list::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #0066a7;
}
.view-modal .modal-body .job-detail-edit{
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    background-color: rgb(6, 98, 185,1);
    border: 1px solid rgb(6, 98, 185,1);
    padding: 7px;
}
.view-modal .modal-body .job-detail-edit img{
    filter: invert(1) brightness(5);
}
.add-job-group .btn-fill.btn-success{
    background-color: #00A651;
    border-color: #00A651;
}
.add-job-group .btn-fill.btn-success:hover{
    background-color:transparent;
    color: #00A651;
}
/* .menu-btn.side-bar-close{
    display: none;
} */
.calendar-view-page .menu-btn.side-bar-close{
    display: block;
    width: 25px;
    height: 25px;
    margin-right:0;
}

/* 28-06-2024 */

.search-filter-section{
    position: relative;
    margin-bottom: 20px;
}
.search-filter-section input{
    padding-right: 45px;
    font-size: 14px;
    border: 1px solid #ddd;
}
.search-filter-section input:focus{
    box-shadow: none;
    outline: none;
    border-color: #ddd;
}
.search-filter-section .search-icon{
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: 1px solid #ddd;
    background-color: #f1f1f1;
    border-radius: 0px 5px 5px 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.filter-collapse .card-body{
    padding: 10px 10px 20px;
    border: 1px solid #ddd;
}
.header-button a.add-btn{
    width: auto;
    padding: 0 20px;
    font-size: 17px;
    font-weight: 600;
    background-color: #0662B9;
    color: #fff;
    text-transform: capitalize;
    border: 1px solid #0662B9;
    height: 55px;
}
.header-button a.add-btn:hover{
    background-color: transparent;
    color: #0662B9;
}
.header-button a.add-btn img{
    margin-right: 15px;
    font-size: 15px;
    font-weight: 500;
}
.header-button a.add-btn:hover img{
    filter: none;
}
.advanced-filter-wrap .advanced-btn{
    z-index: 0;
    width: 100%;
    position: relative;
    margin: 0 0 25px;
    /* display: flex; */
    align-items: center;
    justify-content: center;
}
.advanced-filter-wrap button{
    background-color: #fff;
    border:1px solid #0662B9;
    border-radius: 30px;
    padding: 0 ;
    padding-right: 15px;
    color: #0662B9;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 25px;

}
.advanced-filter-wrap button img{
    width: 16px;
    margin-left: 10px;
    transform: rotate(180deg);
    transition: all 0.2s ease-in-out;
}
.advanced-filter-wrap button.collapsed img{
    transform: rotate(0);
}
.filter-box.calendar--filter{
    z-index: 1;
}
.react-confirm-alert .react-confirm-alert-body{
    padding: 30px 20px;
    font-size: 16px;
    color: #000000;
    font-weight: 500;
}
.react-confirm-alert .react-confirm-alert-button-group{
    margin-top: 20px;
}
.react-confirm-alert .react-confirm-alert-button-group button{
    padding: 8px 15px;
    font-weight: 600;
    /* min-width: 90px; */
    width: 50%;
    height: 38px;
}
/* .react-confirm-alert .react-confirm-alert-button-group button:nth-child(1){
    background-color:#0662B9;
    transition: all 0.2s ease-in-out;
    border: 1px solid #0662B9;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(1) img{
    width: 16px;
    filter: invert(1) brightness(5);
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(1):hover img{
    filter: none;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(1):hover{
    background-color: #fff;
    color: #0662B9;
    border-color: #0662B9;
    transition: all 0.2s ease-in-out;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(2){
    background-color: #fff !important;
    border: 1px solid #0662B9 !important;
    color: #0662B9 !important;
    transition: all 0.2s ease-in-out;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(2):hover{
    color: #fff !important;
    background-color: #0662B9 !important;
    border-color: #0662B9 !important;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(2) img{
    filter: none !important;
    width: 16px;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(2):hover img{
    filter: invert(1) brightness(5) !important;
} */
/* .react-confirm-alert .react-confirm-alert-button-group button:nth-child(3){
    margin-right: 0;
    background-color: #000;
    border: 1px solid #000;
    transition: all 0.2s ease-in-out;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(3) img{
    filter: invert(1) brightness(5);
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(3):hover{
    color: #000;
    background-color: #fff;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(3):hover img{
    filter: none;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(4){
    margin-right: 0;
    background-color: rgb(255, 58, 57);
    border: 1px solid rgb(255, 58, 57);
    transition: all 0.2s ease-in-out;
}
.react-confirm-alert .react-confirm-alert-button-group button:nth-child(4):hover{
    background-color: #fff;
    color: rgb(255, 58, 57);
} */
.calendar-search .search-form-group .custom-react-select .css-t3ipsp-control{
    box-shadow: none;
}
.calendar-search .search-form-group .custom-react-select>span+div{
    border-radius: 0;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border: none;
    border-left: 1px solid #ddd;

}
.calendar-search .select-member .custom-react-select>span+div{
    border-radius: 0;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border: none;
    box-shadow: none;

}
.calendar-search .select-member .custom-react-select>span+div>div:last-child>span{
    display: none;
}
.calendar-search .custom-react-select>span+div{
    border-radius: 0;
    background-color: #EFEFEF;
    color: var(--bs-body-color);
    /* height: 58px; */
    font-size: 14px;
    min-height: auto;
    border: none;

}
.calendar-search .custom-react-select>span+div>div{
    font-size: 16px;
    color: #84818A;
    padding: 2px .75rem;
}
.calendar-search .custom-react-select>span+div>div:first-child{
    color: var(--bs-body-color);
    opacity: 0.8;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    /* height: 55px; */

}
.calendar-search .custom-react-select>span+div>div .css-qbdosj-Input{
    /* height: 48px; */
    font-size: 14px;
}
.calendar-search .custom-react-select .css-t3ipsp-control>div>div:last-child{
    /* height: 54px; */
}
.calendar-search .custom-react-select .css-t3ipsp-control>div>div:last-child{
    /* height: 54px; */
}
.scheduler thead:has(.row-second){
    z-index: 9;
    position: relative;
}


@media(max-width:991px){
    .crew-modal .modal-body {
        padding: 38px 24px 25px;
    }
    .filter-box .form-group .custom-react-select>span+div{
        min-height: 40px;
    }
    .side-menu .side-menu-header a img{
        height: 50px;
        width: auto;
    }
}
@media(max-width:767px){
    .crew-modal .modal-body {
        padding: 38px 13px 25px;
    }
    .crew-modal h3{
        font-size: 23px;
    }
}
@media(max-width:576px){
    .calendar-header .calendar-container .logo img {
        height: 29px;
    }
}


.filter--form .form-group .custom-react-select>div {
 outline: none !important;
 box-shadow: none;
}
.filter-collapse .filter--form{
    overflow-x: hidden;
    overflow-y: auto;
}
.filter-collapse .filter--form::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.filter-collapse .filter--form::-webkit-scrollbar{
	width: 7px;
    height: 8px;
	background-color: #F5F5F5;
}
.filter-collapse .filter--form::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #0066a7;
}
.row-second{
    margin: 0 0 20px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.row-second .ant-col{
    width: 40%;
}
.row-second .ant-col.header-right-col{
    width: 60%;
}
.advanced-btn button.btn{
    height: 68px;
    margin-top: 10px;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: #0662B9;
    border-bottom: 1px solid #0662B9;
    border-radius: 0;
    height: auto;
    width: fit-content !important;
    margin: 0 auto;
}
.advanced-btn button.btn:focus{
    outline: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #0662B9;
    color: #0662B9;
}
.advanced-btn button.btn img{
    margin-left: 10px;

}
/* .advanced-btn button.btn:hover img{
    filter: invert(1) brightness(5);
} */
.calendar-search{
    flex-wrap: nowrap;
    max-width: 400px;
}
.calendar-search .select-member .custom-react-select>span+div>div+div>div{
    padding: 0;
}
.calendar-search .select-member .custom-react-select>span+div>div:last-child{
    padding-left: 0 !important;
}

/* 23-07-2024 */

.scheduler thead>tr>td>.ant-row-flex{
    position: relative;
    z-index: 9;
}
.header-right-col{
    flex: 1;
    padding-left: 40px;
}
.header-right-col .ant-radio-button-wrapper{
    display: inline-flex;
    align-items: center;
    height: 35px;
}
.job-delete-btn{
    width: 38px;
    height: 38px;
    border: 1px solid #ddd;
    padding: 7px;
    border-radius: 50%;
    position: absolute;
    right: 95px;
    top: 13px;
    background: #0662b9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    z-index: 1;
}
.job-delete-btn img{
    max-width: 20px;
    filter: invert(1) brightness(5);
}

@media (max-width:1399px){
    .work-week-btn{
        font-size: 13px;
    }
    .calendar-search .custom-react-select>span+div>div:first-child{
        font-size: 13px;

    }
    .calendar-search .custom-react-select>span+div>div .css-qbdosj-Input{
        font-size: 13px;
    }
}
@media (max-width:991px){
    .calendar-header .calendar-container .select-date{
        width: 100%;
    }
    .calendar-header .calendar-search{
        width: 100%;
        padding-left: 0;
        max-width: 100%;
        margin-right: 0 !important;
        z-index: 2;
    }
}
.calender-toggle {
    display: flex !important;
    flex-wrap: wrap !important;
}
.calender-toggle button{
    width: 100%;
    height: 35px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom: none;
    font-size: 14px;
    font-weight: 400;
    padding: 0 8px;
}
.calender-toggle button:hover{
    color: #1890ff;
}
.calender-toggle label{
    width: 50%;
    text-align: center;
    justify-content: center;
    padding: 0 3px;
}
.import-data-label{
    width: 100%;
    position: relative;
}
.import-data-label a {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 13px;
    font-weight: 500;
    color: #0256a5;
}
.import-collapse , .import-collapse2{
    position: relative;
    /* z-index: 0; */
}
.import-collapse .card-body ,.import-collapse2 .card-body {
    padding: 20px 15px;
    border: 1px solid #ddd;
    box-shadow: none;
    position: relative;
    overflow: visible;
}
.import-collapse::before{
    position: absolute;
    top: 10px;
    left: 30px;
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
    content: "";
    z-index: 0;
    transform:  rotate(45deg);
    transition: all 0.3s ease-in-out;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.import-collapse.show::before{
    top: -10px;
}
.import-collapse::after{
    position: absolute;
    top: -10px;
    left: 35px;
    width: 40px;
    height: 1px;
    background-color: #fff;
    content: "";
    z-index: 0;
    /* transform: ; */
    transition: all 0.3s ease-in-out;
}
.import-collapse.show::after{
    top: 0;
}
.import-collapse .card-body h3 , .import-collapse2 .card-body h3{
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    line-height: 25px;
    margin-bottom: 0;
}
.import-collapse .card-body .card-head , .import-collapse2 .card-body .card-head{
    margin-bottom: 15px;
}
.import-collapse .card-body .card-head .btn , .import-collapse2 .card-body .card-head .btn{
    height: 45px;
    border-radius: 10px;
}
.import-collapse2::before{
    position: absolute;
    top: 10px;
    right: 30px;
    width: 50px;
    height: 50px;
    border: 1px solid #ddd;
    content: "";
    z-index: 0;
    transform: rotate(45deg);
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

}
.import-collapse2.show::before{
    top: -10px;
}
.import-collapse2::after{
    position: absolute;
    top: -10px;
    right: 35px;
    width: 40px;
    height: 1px;
    background-color: #fff;
    content: "";
    z-index: 0;
    transition: all 0.3s ease-in-out;
}
.import-collapse2.show::after{
    top: 0;
}
.timeline-event.disable-foreman{
    /* height: 100%; */
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* cursor: not-allowed; */
}
.timeline-event.disable-foreman .crew-members {
    display: none;
}
.timeline-event.disable-foreman .assets {
    display: none;
}
.timeline-event.disable-foreman :where(.row){
    margin-bottom: 0 !important;
    opacity: 0.5;
}
.notes-footer{
    flex-wrap: wrap;
}
.notes-footer span{
    margin-bottom: 10px;
    width: 100%;
}
.add_icon_mobile .dropdown-toggle{
    background-color: transparent;
    padding: 0;
    outline: none;
    box-shadow: none;

}
.add_icon_mobile .dropdown-toggle img{
    max-width: 21px;
}
.add_icon_mobile .dropdown-toggle::after{
    display: none;
}
.add_icon_mobile .dropdown-menu li{
    border: none;
    margin: 0 ;
    padding: 0;
}
.add_icon_mobile .dropdown-menu li a{
    width: 100%;
    height: auto;
    text-align: start;
    justify-content: start;
    padding: 5px 12px;
    font-size: 15px;
    font-weight: 500;
}
.add_icon_mobile .dropdown-menu li a:hover{
    background-color: #f5f5f5;
    border-radius: 0;
    color: #000;
}
.add_icon_mobile .dropdown-menu{
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 7px 0 ;
}
.attched-file-table{
    border: 1px solid #ddd;
}
.attched-file-table thead th{
    padding: 10px;
    border-right: 1px solid #ddd;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
}
.attched-file-table thead th:first-child{
    width: 30%;
}
.attched-file-table thead th:nth-child(2){
    width: 62%;
}
.attched-file-table thead th:last-child{
    border-right: 0px;
    border-right: 0px;
    width: 8%;
}
.attched-file-table tbody td:first-child{
    width: 30%;
}
.attched-file-table tbody td:nth-child(2){
    width: 60%;
}
.attched-file-table tbody td:last-child{
    /* width: 10%; */
    /* vertical-align: middle; */
}
.attched-file-table tbody td{
    border-right: 1px solid #ddd;
    padding: 10px;
    font-size: 14px;
    color: #000;
    border-bottom: 1px solid #ddd;
}
.attched-file-table tbody td span{
    color: #1675e0;
    display: block;
}
.attched-file-table tbody td span a{
    display: block !important;
}
.attched-file-table tbody td:last-child{
    border-right: 0px;
}
.attched-file-table tbody tr:last-child td{
    border-bottom: 0px;
}
.attched-file-table tbody tr .attched-delete-btn {

    width: 18px;
}
.attched-file-table tbody tr .attched-delete-btn img{
    /* background-color: rgb(6, 98, 185,1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    height: 35px; */
    transition: all 0.2s ease-in-out;
    /* filter: invert(1) brightness(0); */


}
.job-address-text{
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin-bottom: 15px;
}
.job-details-modal.view-modal .modal-body h3{
    margin-bottom: 10px;
}
.react-confirm-alert-body-element .react-confirm-alert-overlay{
    z-index: 9999;
}


.calender-mobile .calender-job-detail .timeline-event{
    padding: 0;
}
.calender-mobile .calender-job-detail .timeline-event h3{
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: #454545;
    margin-bottom: 10px;
}

.list-group-flush .list-group-item.full-view div.map-view{
    border: none;
}
.list-group-flush .list-group-item.full-view div.map-view #googleMapSection{
    height: 100% !important;
}
.list-group-flush .list-group-item.full-view div.map-view .row {
    margin: 0;
    padding: 0;
}
.list-group-flush .list-group-item.full-view div.map-view .row .col-12{
    padding: 0;
    margin-bottom: 0;
}
.list-group-flush .list-group-item.map-view-section{
    height: 100%;
}
.list-group-flush .list-group-item.map-view-section .map-view{
    height: 100%;
}
.list-group-flush .list-group-item.map-view-section .row{
    height: 100%;
}
.list-group-flush .list-group-item.map-view-section .col-12{
    height: 100%;
}
.slot-text small{
    font-size: 11px;
    color: #818181;
    display: block;
}

.modal {
    overflow: auto;
  }

  .modal-dialog {
    max-height: 80vh; /* Adjust this value as needed */
  }

  .modal-body {
    overflow-y: auto;
  }

  /* 09-09-2024 */

  .view-modal .accordion-item .accordion-body:has(.attachment-section){
    padding: 60px 20px 16px;
  }
  .attachment-section{
    position: relative;
  }
  .attachment-section .job-detail-edit{
    position: absolute;
    top: -50px;
    right: 0px;
  }
  .calendar-search .search-filter-section{
    width: 100%;
  }
  .calendar-search .search-filter-section input{
    border-radius: 10px;
    background-color: transparent;
    padding-left: 15px;
    text-overflow: ellipsis;
    padding-right: 55px;
  }
  .calendar-search .search-filter-section button{
    border-radius: 0px 10px 10px 0;
    width: 50px;
  }
  .attachment-section .attched-file-table{
    white-space: nowrap;
  }
  .attachment-section .attched-file-table tbody td a span{
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .calendar-header-right .add_icon_mobile .dropdown-toggle img{
        max-width: 27px;
    }
    .calendar-header-right .add_icon_mobile .dropdown-toggle.show{
        background-color: #0662B9;
    }
    .calendar-header-right .add_icon_mobile .dropdown-toggle.show img{
        filter: invert(1) brightness(5);
    }
    .data-suffle-btns{
        position: fixed;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .data-suffle-btns button.btn-left{
        margin-left: 5px;
    }
    .data-suffle-btns button.btn-right{
        margin-right: 5px;
    }
    .data-suffle-btns button{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #0662B9;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #0662B9;
    }
    .data-suffle-btns button img{
        filter: invert(1) brightness(5);
        max-width: 15px;
    }


@media(max-width:1199px){
    .calendar-header-right .add_icon_mobile .dropdown-toggle img{
        max-width: 21px;
    }
}
@media(max-width:767px){
    .job-data-savebtn button{
        width: 100%;
        margin-right: 0 !important;
        margin-bottom: 15px;
    }
    .job-data-savebtn button{
        height: 50px !important;
        font-size: 15px ;
    }
    .calendar-view-page .rs-picker-popup-daterange .rs-picker-daterange-panel{
        min-width: 100% !important;
    }
    .calendar-view-page .rs-picker-popup-daterange .rs-picker-daterange-panel .rs-picker-daterange-calendar-group {
        min-width: 100%;
        flex-wrap: wrap;
        height: auto;
    }
    .calendar-view-page .rs-picker-popup-daterange.rs-picker-popup .rs-calendar {
        min-width: 100%;
    }
    .job-address-text{
        font-size: 16px;
    }
    .attched-file-table thead th:last-child{
        width: 60%;
    }
    .attched-file-table thead th:first-child{
        width: 40%;
    }
    .view-modal .accordion-item .accordion-body:has(.attachment-section){
        padding: 60px 10px 15px;
    }
}



/* 24-09-2024 */

.calendar-search .search-filter-section input{
    padding-right: 78px;
}
.value-clear-btn{
    position: absolute;
    right: 55px;
    top: 50%;
    transform: translateY(-50%);
    /* height: 56px; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px !important;
    height: 21px !important;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent;
}
.last-update-user{
    font-size: 13px;
    font-weight: 600;
    margin-left: auto;
}
.fiber_task_text{
    position: absolute;
    top: 38px;
    left: 10px;
    font-size: 12px;
    font-weight: 700;
    color: #b00000;
}
.timeline-event {
    padding: 55px 0 10px;
}
.calender-job-detail .event-item {
    padding: 60px 10px 10px !important;
}
.ReactModal__Body--open .ReactModal__Overlay {
    z-index: 99999 !important;
}
.ril__toolbarSide .ril-toolbar__item button{
    background-color: transparent;
}
.ril__toolbarSide .ril-toolbar__item button img{
    filter: invert(1) brightness(5);
    opacity: 0.7;
}
.ril__toolbarSide .ril-toolbar__item button:hover img{
    opacity: 1;
}

@media(max-width:767px){
    .job-details-modal.view-modal .modal-body h3{
        flex-wrap: wrap;
    }
    .job-details-modal.view-modal .modal-body .detail-job-btns{
        width: 100%;
        margin-top: 10px;
        position: absolute;
        top: 8px;
        left: 45px;
    }
    .job-details-modal.view-modal .modal-body .detail-job-btns .job-detail-edit{
        width: 33px;
        height: 33px;
    }
    .job-details-modal.view-modal .modal-body .detail-job-btns .job-detail-edit:last-child{
        margin-right: 15px;
    }
    .job-details-modal.view-modal .modal-body .detail-job-btns button{
        margin-left: 0;
    }
    .job-details-modal.view-modal .modal-body .detail-job-btns button:last-child{
        margin-right: 15px;
    }
    .last-update-user{
        margin-left: 0;
    }
    .view-modal .modal-body{
        padding: 50px 13px 25px !important;
    }
    .list-group-flush .list-group-item.full-view div.map-view #googleMapSection{
        height: 280px !important;
    }
    .react-confirm-alert .react-confirm-alert-body{
        width: 100%;
    }
}


.job-address-text{
    line-height: normal;
}
.job-address-text small{
    font-size: 13px;
}


.event-container .round-all.event-item .row.assets > [class*="col"] div p small{
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
}

.terminate-emp-mob {
    position: relative;
    background-color: #fff !important;
}
.terminate-emp-mob::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    content: "";
}


/*
.timeline-event{
    background-color: #0256A5;
}
*/

.last-update-user{
    text-align: end;
}
.last-update-user p{
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    color: #000;
    display: block;
    margin-bottom: 5px;
}
.last-update-user small{
    font-size: 13px;
    font-weight: 500;
    color: #4e4e4e;
    display: block;
}

.event-container .round-all.event-item .row.crew-members > [class*="col"] div, .event-container .round-all.event-item .row.assets > [class*="col"] div{
    margin-bottom: 0 !important;
}
.timeline-event {
    margin-bottom: 5px !important;
  }
  .timeline-event.disable-foreman .round-all.event-item .text-primary{
    margin-bottom: 10px !important;
  }

  .timeline-event.month-view{
    padding: 25px 0 10px !important;
}
.month-shift-icon{
    display: none;
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
}
.timeline-event.month-view .month-shift-icon{
    display: block;
    top: 3px;
}
.timeline-event.month-view .shifts-icon figure{
    display: none;
}
.event-container .round-all.event-item .text-primary{
    margin-bottom: 23px !important;
}
.fiber_task_text{
    top: 48px !important;
    font-size: 11px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    right: 0;

}
.timeline-event.month-view .fiber_task_text {
    top: 44px !important;
    font-size: 10px !important;
}
.timeline-event.month-view .round-all.event-item .text-primary {
    margin-bottom: 16px !important;
    padding: 0 10px !important;
}

.timeline-event.month-view .kebab-edit-btn{
    display: none;
}
.timeline-event.disable-foreman .fiber_task_text {
    top: 3px !important;
    filter: grayscale(1) brightness(1.9);
    opacity: 0.5;
}
.timeline-event.disable-foreman .event-item .shifts-icon{
    top: 0;
    /* filter: grayscale(1) brightness(1.9); */
    opacity: 0.5;
}
.timeline-event.disable-foreman {
    padding: 19px 0 10px !important;
}
.timeline-event.disable-foreman.month-view .fiber_task_text{
    top: auto !important;
    bottom: 10px !important;
}
.timeline-event.month-view .fiber_task_text{
    top: auto !important;
    bottom: 10px !important;
}
.event-container .round-all.event-item .text-primary span{
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
}

 table.scheduler-bg-table thead tr th.header3-text a span{
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    max-width: 220px;
    margin: 0 auto;
    width: 100%;
}
.month-view table.scheduler-bg-table thead tr th.header3-text a span{
    width: 99px;
    margin: 0 auto;
}
.resource-name  .header2-text .slot-text span{
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
}

.holiday-bg {
    background-color: #f5f5f5;
    background-image: url(../images/holiday-bg-white.jpg);
    background-size: cover;
    background-repeat: no-repeat;

}
.dark-mode .holiday-bg {
    background-image: url(../images/holiday-bg-black.jpg);

}
@media(max-width:1299px){
    .header2-text{
        font-size: 13px;
    }
}

@media (max-width: 1199px){
    .calender-mobile .calender-job-detail .timeline-event.night-shift h3{
        color: #fff ;
    }
    .calender-job-detail .event-item {
        padding: 0px 10px 10px !important;
    }
    .calender-job-detail .timeline-event .event-item .shifts-icon {
        top: -5px;
    }
    .calender-job-detail .round-all.event-item .text-primary{
        margin-bottom: 20px;
    }
    .calender-job-detail .round-all.event-item .text-primary span{
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        white-space: nowrap;
    }
    .calender-job-detail .round-all.event-item .fiber_task_text{
        top: 67px !important;
    }
    .calender-mobile .calender-job-detail .round-all.event-item .fiber_task_text {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        margin-bottom: 10px;
    }
    .calender-job-detail .round-all.event-item .text-primary{
        padding: 0 40px;
    }
}
@media(max-width:767px){
    .job-details-modal.view-modal .modal-body h3{
        display: block;
        line-height: normal;
    }
    .view-modal .modal-body {
        padding: 60px 13px 25px !important;
    }
    .view-modal .shifts-icon{
        top: 17px;
    }
    .last-update-user{
        text-align: left;
        margin: 5px 0 16px;
    }
}





.react-confirm-alert .react-confirm-alert-button-group{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}
.react-confirm-alert .react-confirm-alert-button-group button{
    width: 100%;
}

.job-detail-listing{
    display: grid;
    grid-template-columns: repeat(4 , 1fr);
    gap: 20px;
    margin-bottom: 7px;
}
.job-detail-listing li .form-check{
    padding: 0;
}
.job-detail-listing li .form-check .form-check-label{
    width: 100%;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 30px 12px 15px;
    position: relative;
    text-align: center;
}
.job-detail-listing li .form-check .form-check-input:checked + label{
    background-color: rgba(6, 98 ,185,0.1 );
    border-color: rgb(6 98 185 / 30%);
}
.job-detail-listing li .form-check .jobs-check{
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ddd;
    width: 100%;
    height: 30px;
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
}
.job-detail-listing li .form-check .form-check-input:checked + label .jobs-check{
    background-color: #0662B9;
}
.job-detail-listing li .form-check .form-check-input:checked + label .jobs-check img{
    filter: invert(1) brightness(5);
}
.job-detail-listing li .form-check .jobs-check img{
    filter: invert(1) brightness(0.5);
}
.job-detail-listing li .form-check .form-check-input{
    display: none;
}
.job-detail-listing li .form-check .jobs-date{
    /* display: none; */
    font-size: 15px;
    font-weight: 700;
    color: #000000;
    margin-top: 10px;
}
.job-detail-listing li .form-check span{
    font-size: 12px;
    font-weight: 700;
    color: #4a4a4a;
    word-break: break-all;
}
.job-detail-listing li .form-check p{
    font-size: 12px;
    font-weight: 500;
    color: #4a4a4a;
    word-break: break-all;
}
