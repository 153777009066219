.dark-mode  {
    --bs-black: #fff !important;
}

.dark-mode{
    background-color: #1f2020;
}
.dark-mode .dashboard-wrapper{
    background-color: #1f2020;
}
.dark-mode .top-header  {
    background-color: #121212;
}
.dark-mode .side-menu{
    background-color: #121212;
    border-right: 1px solid #222222;


}
.dark-mode .profile-name{
    color: #fff;
}
.dark-mode .card{
    background-color: #121212;
}
.dark-mode .card-header{
    background-color: #121212;
}
.dark-mode .side-menu-list ul li a img{
    filter: brightness(5) invert(0) grayscale(3);
    opacity: 1;
}
.dark-mode .side-menu-list ul li a.active img{
    filter:none;
    opacity: 1;
}
.dark-mode .side-menu-list ul li a:hover img{
    filter:none;
    opacity: 1;
}
.dark-mode .side-menu-list ul li a:hover{
    color: #0662B9;
}
.dark-mode .side-menu-list ul li a{
    color: #fff;
}
.dark-mode .side-menu-list ul li a.active{
    color: #0662B9;
}
.dark-mode .menu-btn img{
    filter: invert(1 ) brightness(5);
}

.dark-mode .dashboard-filter button.btn{
    background: #181818;
    color: #fff;
    border-color: #2e2e2e;  
} 
.dark-mode .dashboard-filter button.btn.btn-fill{
    color: #fff;
    background-color: #0662B9;
    border-color: #0662B9;
}
.dark-mode .side-menu-list ul li a.collapse-nav.collapsed::before{
    filter: grayscale(1) brightness(5) invert(0);
}
.dark-mode .side-menu-list ul li .accordion-body{
    background-color: #181818;
}
.dark-mode .side-menu-list ul li a[aria-expanded="true"]{
    color: #0662B9;
}
.dark-mode .side-menu-list .subcategories-box .accordion-body{
    background-color: #0e0e0e;
}
.dark-mode .side-menu-list ul li a[aria-expanded="true"] img{
    filter: none;
}
/* .dark-mode .header-profile-inner .dropdown-menu {
    background: #181818 !important;
    border-color: #2e2e2e;
} */

/* 03-12-24 */

.dark-mode .filter-box .accordion-item .accordion-body{
    background-color: #121212;
    border: 1px solid #121212;
    border-radius: 4px;
}
.dark-mode .form-group .form-label{
    color: #fff;
}
.dark-mode .filter-box-header h3 {
    color: #fff;
}
.dark-mode .btn.btn-fill:hover{
   color: #1f2020; 
}
.dark-mode .input-with-label{
    background-color: transparent;
    border: 1px solid #464646;
}
.dark-mode .input-with-label:hover{
    border-color: #464646 !important;
}
.dark-mode .input-with-label .rs-input-group input{
    color: #fff;
}
.dark-mode .input-with-label .rs-input-group input::placeholder{
    color: #fff;
    opacity: 0.8;
}
.dark-mode .filter-box .form-group .custom-react-select>span+div{
    background-color: #121212;
    border: var(--bs-border-width) solid #464646;
}
.dark-mode .filter-box .form-group .custom-react-select .css-13cymwt-control>div>div{
    color: #fff;
}
.dark-mode .filter-box .form-group .custom-react-select .css-t3ipsp-control>div>div{
    color: #fff;
}
.dark-mode .filter-box .form-group .custom-react-select .css-t3ipsp-control>div>div:focus{
    color: #fff;
}
.dark-mode .input-with-label::placeholder{
    color: #fff;
}
.dark-mode .btn.btn-filter::after{
    background-color: #121212;
    border-left: solid 1px #121212;
    border-right: solid 1px #121212;
    border-top: solid 1px #121212;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.dark-mode .input-with-label:focus{
    box-shadow: none;
}
.dark-mode .btn-filter-close{
    filter: invert(1) brightness(5);
}
.dark-mode .btn.btn-filter img{
    filter: invert(1) brightness(5);
    
}
.dark-mode .btn.btn-fill:hover img{
    filter: none;
}

.dark-mode .table-count.form-group .custom-react-select>span+div{
    background-color: #121212;
    border-color: #343434;
}

.dark-mode .table-count{
    color: #878787;
}
.dark-mode .table-count.form-group .custom-react-select>div>div>div:first-child{
    color: #fff;
}
.dark-mode .custom-react-select .css-tj5bde-Svg{
    color: #fff;
}
.dark-mode .input-with-label{
    color: #fff;
    opacity: 0.8;
} 
.dark-mode .theme-table table.table th{
    background-color: #161616;
    color: #fff;
    border-bottom-color:#363636 ;
}
.dark-mode .theme-table table.table td{
    background-color: #121212;
    color: #fff;
}
.dark-mode .theme-table table.table tbody tr:nth-child(even) td{
    background-color: #141414;
    
}
.dark-mode .filter-box .form-floating input{
    background-color: #1f2020;
    border-color: #1f2020;
    color: #fff;
    opacity: 0.8;
}

.dark-mode .adduser-form .form-floating label{
    color: #fff;
}
.dark-mode .filter-box .form-floating>input:not(:placeholder-shown)~label{
    color: #919191;
}
.dark-mode .adduser-form .form-floating label::after{
    background-color: transparent !important;
}
.dark-mode .filter-box .form-floating>input:not(:placeholder-shown)~label::after, .filter-box .form-floating>label::after{
    background-color: transparent !important;
}

/* calender dark mode */

.dark-mode .calendar-header{
    background-color: #121212;
    box-shadow: 0px 3px 3px #9595954d;
}
.dark-mode .ant-row{
    background-color: #121212;
}
.dark-mode .source-list{
    background-color: #1f2020;
    border: 1px solid #303030;
}
.dark-mode .header2-text{
    color: #ededed;
}
.dark-mode .calendar-search .search-filter-section input{
    color: #fff;
    opacity: 0.9;
    border-color: #787878;
}
.dark-mode .calendar-search .search-filter-section input::placeholder{
    color: #fff;
    opacity: 0.9;
}
.dark-mode .search-filter-section .search-icon{
    background-color: #f5f5f5;
    border-color: #f5f5f5;
}
.dark-mode .work-week-btn{
    color: #ededed;
    border-color: #787878;
}
.dark-mode .work-week-btn .layer{
    border-color: #626262;
    background-color: #363636;
}
.dark-mode .work-week-btn #button-3 .checkbox:checked ~ .layer{
    border-color: #0662B9;
}
.dark-mode .calender-toggle button {
    background-color: #1f2020;
    border: 1px solid #4c4c4c;
    color: #cdcdcd;
    transition: all 0.2s ease-in-out;
}

.dark-mode .calender-toggle label{
    background-color: #1f2020;
    border-color: #4c4c4c;
    color: #cdcdcd;
}
.dark-mode .calender-toggle label:hover{
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
}
.dark-mode .calender-toggle label::before{
    background-color: #4c4c4c;
}
.dark-mode .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
    box-shadow: -1px 0 0 0 #1890ff;
}
.dark-mode .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #1890ff !important;
    opacity: 0.1;
}
.dark-mode .sourcebox h4{
    color: #ededed;
}
.dark-mode .search-filter-section input{
    color: #fff;
    opacity: 0.9;
    border-color: #787878;
    background: #1f2020;
}
.dark-mode .search-filter-section input::placeholder{
    color: #fff;
    opacity: 0.9;
}
.dark-mode .value-clear-btn{
    filter: invert(1) brightness(0.9);
}
.dark-mode .header-button ul li{
    border-color: #787878;
}
.dark-mode .sourcebox {
    border-color: #787878;
}
.dark-mode table.resource-table thead tr th, .dark-mode table.scheduler-bg-table thead tr th{
    border-color: #1f2020;
}
.dark-mode .scheduler-view{
    border-color: #303030;
}
.dark-mode table.resource-table th, table.scheduler-table th, .dark-mode table.resource-table td, .dark-mode table.scheduler-bg-table td, .dark-mode table.scheduler-table td {
    border-right: 1px solid #303030;
    border-bottom: 1px solid #303030;
    background-color: #1f2020;
}
.dark-mode .resource-view{
    border-color: #303030;

}
.dark-mode .dark-mode table.resource-table thead tr th, .dark-mode table.scheduler-bg-table thead tr th{
    border-color: #303030;

}
.dark-mode .scheduler-view--wrap{
    border-color: #303030 !important;
}
.dark-mode .resource-view>div{
    border-color: #303030 !important;
}
.dark-mode .calendar-header .calendar-container .select-date .form-floating input{
    border: 1px solid #787878;
    background-color: #1f2020;
    color: #cdcdcd;
}
.dark-mode .calendar-header .calendar-container .select-date label{
    color: #ededed;
}
.dark-mode .slot-text small{
    color: #c5c5c5;
}
.dark-mode .full-screen-button{
    filter: invert(1) brightness(0.9);
}
.dark-mode .reset-icon{
    filter: invert(1) brightness(5);
}

.dark-mode .sourcebox-filter .dropdown{
    filter: invert(1) brightness(5);
}
.dark-mode .day-shift {
    background-color: #444444;
    border: solid 1px #7a7a7a;
}
.dark-mode .event-container .round-all.event-item .text-primary{
    color: #E0E0E0 !important;
}
.dark-mode .timeline-event .event-item .shifts-icon span{
    color: #E0E0E0  ;
}
.dark-mode .fiber_task_text{
    font-weight: bold;
    color: #FF5C5C;
}
.dark-mode .timeline-event.disable-foreman .round-all.event-item .text-primary{
    color: #000 !important;
    opacity: 0.5;
}
.dark-mode .timeline-event.disable-foreman :where(.row){
    opacity: 1;
}
.dark-mode .timeline-event.disable-foreman{
    background-color: #B1B1B1;
    color: #000;
}
.dark-mode .timeline-event.disable-foreman .fiber_task_text{
    text-shadow: none;
    color: #000;
    opacity: 0.3;
    font-weight: 700;
}
.dark-mode .timeline-event.disable-foreman .shifts-icon span{
    color: #000;
    opacity: 0.9;
}
.dark-mode .timeline-event.draft-shift {
    background-color: #7A4B5D;
}
.dark-mode .kebab-edit-btn{
    filter: invert(1);
}
.dark-mode table.resource-table thead tr th, .dark-mode table.scheduler-bg-table thead tr th {
    background: transparent linear-gradient(180deg, #0c4983 0%, #0a3258 100%) 0% 0% no-repeat padding-box;
}
.dark-mode .night-shift .kebab-edit-btn {
    filter: none;
}
.dark-mode .filter-header-close  {
    filter: invert(1) brightness(5);
}
.dropdown-filter-header strong{
    color: #c5c5c5;
}
.dark-mode .filter-collapse .card-body{
    border-color: #5c5c5c;
}
.dark-mode .filter--form .form-group .custom-react-select .css-13cymwt-control{
    background-color: #121212;
    border-color: #787878;
    /* color: #fff; */
}
.dark-mode .filter--form .form-group .custom-react-select>div>div>div {
    color: hsl(0, 0%, 50%) !important;
}
.dark-mode .crew-modal .modal-dialog{
    background-color: transparent;
}
.dark-mode .crew-modal .modal-dialog .modal-content{
    background-color: #121212;
    backdrop-filter: blur(20px);
    border-color: #464646;
}
.dark-mode .view-modal .modal-dialog{
    background-color: #000;
}
.dark-mode .view-modal .modal-dialog .modal-content{
    background-color: #121212;
    backdrop-filter: blur(20px);
    border-color: #464646;
}
.dark-mode .crew-modal h3 ,.dark-mode .view-modal h3{
    color: #F4F4F4;
}
.dark-mode .crew-modal .btn-close ,.dark-mode .view-modal .btn-close{
    color: #F4F4F4;

}
.dark-mode .checkout-process-strip li{
    background-color: #121212;

}
.dark-mode .checkout-process-strip li p{
    color: #F4F4F4;

}
.dark-mode .checkout-process-strip li .process-inner-wrap{
    background-color: #121212;
    border-color: #bbbbbb;
}
.dark-mode .checkout-process-strip li:hover .process-inner-wrap {
    background-color: #0662b9;
    border-color: #0662b9;
    color: #fff;
}
.dark-mode .checkout-process-strip li:hover .process-inner-wrap span button{
    color: #fff;
    
}
.dark-mode .checkout-process-strip li.completed .process-inner-wrap {
    background-color: #0662b9;
    border-color: #0662b9;
    color: #fff;
}
.dark-mode .related-label-fields{
    border-color: #464646;
}
.dark-mode .related-label-fields .related-label-head{
    background-color: #121212;
    color: #F4F4F4;
}
.dark-mode .checkout-process-strip::before{
    background-color: #bbbbbb;
}
.dark-mode .crew-modal .form-group .css-13cymwt-control{
    border-color: #464646;
}
.dark-mode .crew-modal .form-group .custom-react-select .css-13cymwt-control>div>div{
    color: #F4F4F4;
}
/* .dark-mode .crew-modal .form-group .custom-react-select>div>div>div:focus{
    color: #000;
} */
.dark-mode .crew-modal .form-group .custom-react-select .css-t3ipsp-control>div>div{
    color: #000;
}
/* .dark-mode .crew-modal .form-group .custom-react-select .css-t3ipsp-control .css-tj5bde-Svg{
    color: #000;
}  */
.dark-mode .crew-modal .form-group select, .dark-mode .crew-modal .form-group input.form-control{
    background-color: #121212;
    border-color: #464646;
    color: #F4F4F4;
}
.dark-mode .crew-modal .form-group textarea.form-control{
    background-color: #121212;
    border-color: #464646;
    color: #F4F4F4;
}
.dark-mode .crew-modal .form-group textarea.form-control::placeholder{
    color: #F4F4F4;
    opacity: 0.8;
}
.dark-mode .crew-modal .form-group select, .dark-mode .crew-modal .form-group input.form-control::placeholder{
    background-color: #121212;
    border-color: #464646;
    color: #F4F4F4;
    opacity: 0.8;
}
.dark-mode .crew-modal .form-group .custom-react-select>span+div{
    background-color: #121212;
    border-color: #464646;
    color: #f4f4f4;
}
.dark-mode .crew-modal .form-group .custom-react-select .css-t3ipsp-control>div>div{
    color: #f4f4f4;
}


.dark-mode .custom-checkbox label{
    background-color: #121212;
    border-color: #464646;
    color: #f4f4f4;
}
.dark-mode .import-collapse .card-body, .dark-mode .import-collapse2 .card-body{
    border-color: #464646 !important;

}
.dark-mode .import-collapse::before , .dark-mode .import-collapse2::before{
    border-color: #464646 ;
}
.dark-mode .import-collapse::after , .dark-mode .import-collapse2::after{
    background-color: #121212 ;
}
.dark-mode .view-modal .accordion-item{
    background-color: #000;
    border-color: #464646;
}
.dark-mode .view-modal .accordion-item .accordion-collapse{
    border-color: #464646;

}
.dark-mode .view-modal .accordion-item .accordion-header button{
    border-color: #464646;

}
.dark-mode .list-group-flush .list-group-item{
    border-color: #464646;

}
.dark-mode .list-group-flush .list-group-item.full-view p{
    color: #f4f4f4;
    background-color: #202020;
}
.dark-mode .list-group-flush .list-group-item.full-view>div{
    color: #f4f4f4;
    border-color: #464646;
    
}
.dark-mode .attchment-edit{
    background-color: #121212;
    border-color: #464646;
}
.dark-mode .attchment-edit textarea.form-control{
    background-color: #121212;
    border-color: #464646;
    color: #F4F4F4;
}
.dark-mode .modal .dropzone{
    border-color: #464646 !important;
}
.dark-mode .modal .dropzone p{
    color: #f4f4f4;
}
.dark-mode .form-label{
    color: #f4f4f4;
}
.dark-mode .form-floating>.form-control:not(:placeholder-shown)~label::after{
    background-color: #121212;
}
.dark-mode .notes-list li{
    background-color: #121212;
    border-color: #464646;
}
.dark-mode .notes-body p{
    color: #f4f4f4;
}
.dark-mode .notes-footer{
    border-color: #464646;
}
.dark-mode .notes-list .notes-footer span{
    color: #f4f4f4;
}
.dark-mode .notes-list .notes-footer span img{
    filter: invert(1) brightness(2);
}
.dark-mode .modal-footer{
    border-color: #464646;
}
.dark-mode .add_icon_mobile .dropdown-menu{
    background-color: #121212;
    border-color: #464646;
}
.dark-mode .add_icon_mobile .dropdown-menu li a{
    color: #fff;
}
.dark-mode .header-profile-inner .dropdown-menu{
    background-color: #121212;
    border:1px solid #464646;
}   
.dark-mode .header-profile-inner .dropdown-menu li a{
    color: #f4f4f4;
}
.dark-mode .header-profile-inner .dropdown-menu li a:hover{
    color: #000;
}
.dark-mode .header-profile-inner .dropdown-menu li a:focus{
    color: #000;
    background-color: #fff;
}

.dark-mode .job-address-text{
    color: #f4f4f4;
}
.dark-mode .last-update-user small {
    color: #b9b9b9;
}
.dark-mode .attachmentBoxes-wrap .attachmentBoxes-btn{
    background-color: #fff;
    color: #000;
}
.dark-mode .attachmentBoxes-wrap .attachmentBoxes-btn::after{
    filter: none;
}
.dark-mode .attachmentBoxes-wrap .attachmentBoxes-btn.collapsed{
    background-color: #0662b9;
    color: #fff;
    /* filter: invert(1) brightness(5); */
}
.dark-mode .attachmentBoxes-wrap .attachmentBoxes-btn.collapsed::after{
    filter: invert(1) brightness(5);
}
.dark-mode .attachment--boxes{
    border-color: #464646;
}
.dark-mode .attachment--boxe .description-column p{
    color: #f4f4f4;
}
.dark-mode.react-confirm-alert-body-element .react-confirm-alert-overlay{
    background-color: rgba(0, 0, 0, 0.9);
}

.dark-mode .react-confirm-alert .react-confirm-alert-body{
    background: #121212;
    border: 1px solid #464646;
    color: #f4f4f4;
}
.dark-mode .text-black{
    color: #f4f4f4 !important;
}
.dark-mode .users-list .input-with-label:hover{
    border-color: transparent !important;
}
.dark-mode .add-employee {
    background-color: #121212;
    border-radius: 10px;
}
.dark-mode .adduser-form .form-floating .form-control{
    background-color: transparent;
    border-color: #464646;
    color: #f4f4f4;
}
.dark-mode .adduser-form .form-floating>.form-control-plaintext~label, .dark-mode .adduser-form .form-floating>.form-control:focus~label, .dark-mode .adduser-form .form-floating>.form-control:not(:placeholder-shown)~label, .dark-mode .adduser-form .form-floating>.form-select~label{
    background-color: #121212;
    transform: scale(.85) translateY(-1.2rem) translateX(0.15rem);
    height: auto;
    padding:5px .75rem;
}
.dark-mode .custom-form-group .custom-react-select>span+div{
    background-color: transparent;
    border-color: #464646;
    color: #f4f4f4 !important;
}
.dark-mode .custom-form-group .custom-react-select .css-13cymwt-control>div>div {
    color: #f4f4f4 !important;
    opacity: 1;
}
.dark-mode .custom-form-group .custom-react-select .css-13cymwt-control>div>div:focus {
    color: #f4f4f4 !important;
    opacity: 1;
}
.dark-mode .custom-form-group .custom-react-select .css-13cymwt-control>div>div label {
    color: #f4f4f4 !important;
    opacity: 1;
}
.dark-mode .custom-form-group .custom-react-select .css-t3ipsp-control>div>div {
    color: #f4f4f4 !important;
    opacity: 1;
}
.dark-mode .custom-form-group .custom-react-select .css-13cymwt-control>div>div:focus {
    color: #f4f4f4 !important;
    opacity: 1;
}
.dark-mode .form-check-group label{
    color: #cfcfcf;
}
.dark-mode .adduser-form .employee-submit{
    border-color: #464646;
}
.dark-mode .custom-upload label{
    background-color: #121212;
    border-color: #464646;
    color: #f4f4f4;

}
.dark-mode .custom-upload label p{
    color: #f4f4f4;
}
.dark-mode .users-list{
    background-color: transparent;
}
.dark-mode .table-responsive .user-type-link, .dark-mode .table-responsive .user-type{
    color: #f4f4f4;
}
.dark-mode .subcontractor-modal .modal-dialog .modal-content {
    background-color: #121212;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-color: #464646 !important;
    box-shadow: none !important;
}
.dark-mode .subcontractor-modal#adduserModal .modal-title {
    color: #f4f4f4;
}
.dark-mode .subcontractor-modal#adduserModal .btn-close {
    color: #f4f4f4;
}

.dark-mode .employee-table-list ul li {
    color: #f4f4f4;
    background-color: #000;
    border: 1px solid #464646;
}
.dark-mode #adduserModal .modal-dialog .modal-content {
    background-color: #121212;
    box-shadow: 0px 20px 20px #0662B94D;
    border: 1px solid #464646;
}
.dark-mode #adduserModal .modal-title{
    color: #f4f4f4;
}
.dark-mode #adduserModal .btn-close{
    color: #f4f4f4;
}
.dark-mode .custom-table tr th , .dark-mode .custom-table tr td{
    background-color: #121212;
    color: #f4f4f4;


}
.dark-mode .department-cost th p{
    color: #f4f4f4;
}
.dark-mode .action-button{
    background-color: #121212;
    border-color: #464646
}
.dark-mode .moreaction a img{
    filter: brightness(0) invert(1)
}
.dark-mode .apexcharts-text{
    color: #d1d1d1 !important;
    fill: #d1d1d1 !important;
}
.dark-mode .apexcharts-legend-text{
    color: #d1d1d1 !important;
    fill: #d1d1d1 !important;
}
.dark-mode .small-graph-content span {
    color: #cdcdcd;
}

.dark-mode .header-profile-inner .dropdown-menu li:has(.visibility-mode) span{
    color: #f4f4f4;
}
.dark-mode .header-logo img.logo-basic{
    display: none;
}
.dark-mode .header-logo img.logo-white{
    display: inline-block;
}
.dark-mode .mapModal .modal-content{
    background-color: #121212;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-color: #464646;
}
.dark-mode .mapModal h3{
    color: #f4f4f4;
}
.dark-mode .mapModal .btn-close{
    color: #f4f4f4;
}

.dark-mode  .profiledetail-head span{
    color: #f4f4f4;
}
.dark-mode  .profiledetail-head span{
    color: #f4f4f4;
}
.dark-mode  .details-listing li .inner-wrap p{
    color: #b1b1b1;
}
.dark-mode  .details-listing li .inner-wrap span{
    color: #f4f4f4;
}
.dark-mode .detail-modal-editbtns a img {
    filter: invert(1) brightness(2.5);
}

.dark-mode .detail-modal-editbtns a{
    color: #f4f4f4;
    background-color: #000;
}
.dark-mode .detail-modal-editbtns a:hover{
    background-color: #0662B9;
}
/* .dark-mode */
/* .dark-mode */
/* .dark-mode */
/* .dark-mode */
/* .dark-mode */
/* .dark-mode */
/* .dark-mode */
/* .dark-mode */
/* .dark-mode */
@media(max-width:1199px){
    .dark-mode .calender-job-detail .round-all.event-item .text-primary span{
        color: #f4f4f4 !important;
    }
    .dark-mode .calender-job-detail .round-all.event-item .text-primary{
        color: #f4f4f4 !important;
    }
    .dark-mode .calender-mobile .calender-job-detail .timeline-event h3{
        color: #d7d7d7 !important;
    }
}