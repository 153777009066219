:root {
    --primary-font-family: "Poppins", sans-serif;
    --primary-color: #0662B9;
    --theme-light: rgba(250, 233, 202, 0.53);
    --font-medium: 500;
    --font-regular: 400;
    --font-semi-bold: 600;
    --color-text: #333333;
    --placeholder-color: #9A9A9A;
    --bg-theme: #F8F5EF;
    --bs-font-size: 1.25rem;
    --bs-theme-color: #C9984D
}

body {
    font-family: var(--primary-font-family);
    font-weight: var(--font-regular);
    font-size: 16px;
}
.form-group .form-label{
    font-size: 15px;
    font-weight: 500;
    color: #000000;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a,
a:hover {
    text-decoration: none !important;
}

img {
    max-width: 100%;
    height: auto;
}

.transition-4s {
    transition: 0.4s all;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    -ms-transition: 0.4s all;
    -o-transition: 0.4s all;
}

@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1280px;
    }
}

h2.heading {
    font-size: 2.5rem;
    color: var(--bs-black);
    font-weight: var(--font-semi-bold);
}

/* Button CSS */
.btn.btn-fill {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--bs-white);
    border-radius: 0.875rem;
    display: inline-flex;
    align-items: center;
    padding: 0 1.5rem;
    height: 3.75rem;
    transition: 0.4s all;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    -ms-transition: 0.4s all;
    -o-transition: 0.4s all;
    justify-content: center;
}

.btn.btn-fill:hover {
    background-color: var(--bs-black);
    border-color: var(--bs-black);
    color: var(--bs-white);
}

.btn.btn-outline {
    background-color: transparent;
    border-color: var(--primary-color);
    color: var(--primary-color);
    border-radius: 0.875rem;
    display: inline-flex;
    align-items: center;
    padding: 0 1.5rem;
    height: 3.75rem;
    transition: 0.4s all;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    -ms-transition: 0.4s all;
    -o-transition: 0.4s all;
    justify-content: center;
}

.btn.btn-outline:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--bs-white);
}

/* Button CSS */


@media (max-width: 767.98px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 100%;
    }

    .form-group input,
    .form-group textarea,
    .custom-file-upload label {
        font-size: 0.825rem;
    }
}

/* From Element CSS */

.login-page {
    min-height: 100vh;
    background-image: url(../images/login-bg.png);
    background-size: cover;
    background-position: bottom;
    display: flex;
    align-items: center;
    padding: 2rem 0;
}

.login-box {
    box-shadow: 0px 20px 20px #0662B94D;
    border: 1px solid #E5F2FF;
    border-radius: 2rem;
    padding: 4rem 3rem 4rem 3rem;
    background-color: #fff;
}

.login-box h1 {
    font-size: 3.125rem;
    font-weight: 500;
    color: #202020;
    margin-bottom: 2.5rem;
}

.login-left {
    text-align: center;
    max-width: 477px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.6;
}

.login-left p {
    font-size: 1.25rem;
}

.login-box .btn.btn-fill {
    min-width: 15rem;
}

.forgot-password {
    color: #47464A;
}

.forgot-password:hover {
    color: var(--primary-color);
    text-decoration: underline;
}

.login-box .form-floating .form-control {
    padding: 1.5rem .75rem;
    height: auto;
    font-size: 1.1875rem;
}

.login-box .form-floating>label {
    padding: 1.5rem .75rem;
    transition: opacity .1s ease-in-out, transform .3s ease-in-out;
    color: #84818A;
    font-size: 1.1875rem;
}

.login-box .form-floating {
    margin-bottom: 2rem;
}

.login-box .form-floating>.form-control-plaintext~label,
.login-box .form-floating>.form-control:focus~label,
.login-box .form-floating>.form-control:not(:placeholder-shown)~label,
.login-box .form-floating>.form-select~label {
    transform: scale(.85) translateY(-2.2rem) translateX(0.15rem)
}

.side-menu {
    width: 345px;
    box-shadow: 18px 4px 35px #00000005;
    background-color: var(--bs-white);
    padding: 1.225rem 0 2rem 0;
    height: 100vh;
    position: fixed;
    z-index: 1;
    top: 0;
    z-index: 99;
    left: 0;
    transition: 0.5s all;
}

.side-menu.hide {
    left: -100%;
}

.side-menu .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #0662B9;
}

.side-menu .mCSB_scrollTools .mCSB_draggerRail {
    display: none;
}

.side-menu .side-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-wrapper {
    background-color: #F9F9F9;
    display: flex;
    flex-wrap: wrap;
}

.menu-btn {
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-right: 15px;
}

.side-menu-header {
    margin-bottom: 3rem;
}

.side-menu-heading {
    color: #C7C7C7;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.side-menu-list {
    overflow: hidden;
}

.side-menu-list .rc-scrollbars-container {
    max-height: calc(100vh - 170px);
    min-height: calc(100vh - 170px);
}

.side-menu-list .accordion>li,
.work-with,
.side-menu-heading,
.side-menu .side-menu-header {
    padding: 0 3rem;
}

.side-menu-list ul li a {
    font-size: 18px;
    font-weight: 600;
    display: block;
    padding: 0.625rem 0;
    color: #A5A5A5;
}

.side-menu-list ul li a:hover,
.side-menu-list ul li a.active,
.side-menu-list ul li a[aria-expanded="true"] {
    color: #0662B9;
}

.side-menu-list ul li a img {
    margin-right: 1rem;
    filter: brightness(0) invert(0);
    opacity: 0.3;
}

.side-menu-list ul li a:hover img,
.side-menu-list ul li a.active img,
.side-menu-list ul li a[aria-expanded="true"] img {
    filter: brightness(1) invert(0);
    opacity: 1;
}

.work-with-cannon {
    background: rgb(6, 98, 185);
    background: -moz-linear-gradient(144deg, rgba(6, 98, 185, 1) 0%, rgba(100, 246, 255, 1) 100%);
    background: -webkit-linear-gradient(144deg, rgba(6, 98, 185, 1) 0%, rgba(100, 246, 255, 1) 100%);
    background: linear-gradient(144deg, rgba(6, 98, 185, 1) 0%, rgba(100, 246, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0662b9", endColorstr="#64f6ff", GradientType=1);
    border-radius: 2rem;
    padding: 2rem 1.5rem;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-top: 2.5rem;
}

.work-with-cannon::after {
    width: 10.625rem;
    height: 10.625rem;
    bottom: -2.8125rem;
    right: -4.5rem;
    position: absolute;
    content: '';
    background-image: url(../images/circle.svg);
    background-position: center;
    background-size: 100%;
}

.work-with-cannon h3 {
    font-size: 20px;
    color: var(--bs-white);
}

.side-menu-list ul li .accordion-body {
    background-color: #f5f5f5;
    border-radius: 1rem;
}

.side-menu-list ul li .accordion-body a {
    font-size: 1rem;
    padding: 0.5rem 0;
}


.top-header {
    background-color: var(--bs-white);
    position: sticky;
    top: 0;
    padding: 1.625rem 3.125rem;
    flex: 0 0 auto;
    width: calc(100% - 345px);
    align-self: flex-start;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 9;
}

.top-search {
    max-width: 491px;
    flex: 1;
}

.notification-link {
    margin-right: 4.5rem;
}

.top-search .form-group {
    position: relative;
}

.top-search .form-group span {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateY(-50%);
}

.top-search input {
    height: 4.25rem;
    padding: 0 1rem 0 4rem;
    background-color: #FAFAFA;
    border-radius: 10rem;
    border: 0;
}

.side-menu-list .mCSB_inside>.mCSB_container {
    margin-right: 0 !important;
}

.top-header-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.notification-link .badge {
    padding: 5px 7px;
    border: solid 3px var(--bs-white);
}

.header-profile-inner .dropdown-toggle {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding-right: 65px;
}

.profile-name {
    font-size: 1rem;
    color: #000;
}

.profile-type {
    font-size: 0.875rem;
    color: #8F8F8F;
}

.profile-info {
    padding-left: 1.5rem;
    cursor: pointer;
}

.header-profile-inner .dropdown-toggle figure {
    width: 3.5625rem;
    height: 3.5625rem;
    border-radius: 0.825rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border: 1px solid #ddd;
}
.header-profile-inner .dropdown-toggle figure img{
    object-fit: cover;
}

.dropdown-menu {
    width: 100%;
    padding: 1rem 0;
    border-radius: 0.625rem;
    box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
    border: 0;
    transition: 0.4s all;
    min-width: 200px;
}

.header-profile-inner .dropdown-toggle::after {
    background: url("../images/arrow-gray-down.svg") no-repeat;
    height: 9px;
    width: 18px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: transparent;
    border: none;
}

.header-profile-inner .dropdown-menu li a {
    font-size: 0.875rem;
}

.dashboard-right {
    width: calc(100% - 345px);
    margin-left: 345px;
    transition: 0.4s all;
}

.side-menu-open .dashboard-right {
    width: 100%;
    margin-left: 0;
}

.dashboard-heading {
    margin-bottom: 2rem;
}

.dashboard-heading h1 {
    font-size: 2.125rem;
    color: var(--bs-black);
    font-weight: bold;
    line-height: 46px;
}

.dashboard-content {
    padding: 2rem 3.125rem;
}

.dashboard-content .dashboard-heading p {
    font-size: 1.125rem;
    color: #A5A5A5;
}

.card {
    box-shadow: 0px 4px 4px #0000001A;
    background-color: var(--bs-white);
    border: 0;
    border-radius: 0.875rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
}

.dashboard-boxes .card-body {
    padding: 1.825rem;
}

.small-graph-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.small-graph {
    flex: 0 0 auto;
    width: 113px;
    height: 113px;
}

.small-graph>div {
    width: 100% !important;
    display: flex;
    align-items: center;
    height: 100% !important;
}

.small-graph svg {
    width: 100% !important;
    height: 100% !important;
}

.small-graph-content {
    flex: 0 0 auto;
    padding-left: 1.825rem;
    width: calc(100% - 113px);
}

.small-graph-content strong {
    font-size: 2.225rem;
    color: var(--bs-black);
    display: block;
}

.small-graph-content span {
    font-size: 1.125rem;
    color: #858585;
}

.card-body {
    padding: 1.875rem 1.875rem 1.875rem 1.875rem;
}

.card-header {
    padding: 1.875rem 1.875rem 0 1.875rem;
    background-color: var(--bs-white);
    border: 0;
}

.card-header>h5 {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--bs-black);
}

.card-header-right {
    margin-left: auto;
}

.card-header-right select {
    height: 2.225rem;
    padding: 0 2rem 0 1rem;
    border: 1px dashed #F5F5F5;
    border-radius: 0.725rem;
    background-image: url(../images/arrow-down.svg);
}

.more-menu.dropdown .dropdown-toggle {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown.more-menu .dropdown-toggle::after {
    display: none;
}

.total-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.total-wrap .total-left {
    flex: 0 0 auto;
    width: 56px;
}

.total-wrap .total-left>div {
    width: 100% !important;
    height: 100% !important;
}

.total-wrap .total-left>div svg {
    width: 100% !important;
    height: 100% !important;
}

.total-right {
    flex: 0 0 auto;
    width: calc(100% - 56px);
    padding-left: 1rem;
}

.total-right strong {
    font-size: 1.5rem;
    display: block;
}

.total-right span {
    font-size: 1rem;
    color: #202020;
}

.card-statistics-point ul {
    display: flex;
    flex-wrap: wrap;
}

.card-statistics-point ul li {
    position: relative;
    padding-left: 2rem;
    margin-right: 2rem;
}

.card-statistics-point ul li:last-child {
    margin-right: 0;
}

.card-statistics-point ul li strong {
    font-size: 1.5rem;
    display: block;
}

.card-statistics-point ul li span {
    font-size: 1rem;
    color: #202020;
}

.card-statistics-point ul li .dot {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    top: 11px;
    left: 0;
    border-radius: 50%;
}

.card-statistics {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.graphWrap {
    margin-top: 2rem
}

.more-menu .dropdown-menu {
    width: 200px;
}

.task-table {
    vertical-align: middle;
    margin-top: 1rem;
    white-space: nowrap;
}

.task-table td,
.task-table th {
    border: 0;
    padding: 0.875rem;
}

.task-status {
    position: relative;
    padding-left: 2.1rem;
    background-repeat: no-repeat;
    background-position: left 4px;
    background-size: 20px 20px;
}

.task-status.closed {
    background-image: url(../images/closed-task.svg);
}

.task-status.pending {
    background-image: url(../images/pending-task.svg);
}

.task-status span {
    font-size: 14px;
    color: #A5A5A5;
    font-weight: 600;
}

.task-project span {
    font-size: 14px;
    color: #A5A5A5;
    font-weight: 400;
}

.card-statistics {
    margin-top: 1rem;
}

.people-list {
    margin-bottom: 20px;
}

.people-list ul li {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.people-list ul li:last-child {
    margin-bottom: 0;
}

.people-list ul li figure {
    width: 50px;
    height: 50px;
    border-radius: 14px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D8D8D8;
}

.people-list ul li figure img {
    max-width: 100%;
    height: 100%;
}

.people-list-info {
    padding-left: 1rem;
    width: calc(100% - 50px);
}

.people-list-info strong {
    font-size: 1.124rem;
    color: var(--bs-black);
    display: block;
}

.people-list-info span {
    font-size: 14px;
    color: #A5A5A5;
}

.recent-project ul {
    margin: 0 -1.875rem;
}

.recent-project ul li {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 1rem 1.875rem;
}

.recent-project ul li::before {
    height: 0;
    width: 5px;
    background-color: #0662B9;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    border-radius: 18px;
    transition: 0.4s all;
}

.recent-project ul li:hover::before {
    height: 100%;
}

.recent-project ul li .recent-project-details {
    flex: 0 0 auto;
    width: calc(100% - 164px);
    padding-right: 1rem;
}

.recent-project ul li .recent-project-details strong {
    font-size: 18px;
}

.recent-project ul li .recent-project-details p {
    font-size: 14px;
}

.recent-project ul li .recent-project-button a {
    width: 164px;
}

.right-button {
    display: flex;
}

.btn.btn-filter {
    padding: 0;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #707070;
    background-color: transparent;
    margin-right: 20px;
    position: relative;
    z-index: 2;
}

.btn.btn-filter::after {
    height: 15px;
    position: absolute;
    top: -1px;
    width: 100%;
    content: "";
    border-left: solid 1px #707070;
    ;
    border-right: solid 1px #707070;
    border-top: solid 1px #707070;
    background-color: #fff;
    box-sizing: content-box;
    transition: 0.4s all;
    border-top-left-radius: 0.875rem;
    border-top-right-radius: 0.875rem;
    z-index: -1;
    opacity: 0;
}

.btn.btn-filter[aria-expanded="true"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #fff;
    border-color: #707070;
}

.btn.btn-filter[aria-expanded="true"]::after {
    height: 77px;
    opacity: 1;
}

.btn.btn-filter:hover {
    background-color: #fff;
    border-color: #707070;
}

/* .btn.btn-filter:hover img{
    filter: brightness(0) invert(1);
} */
.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--bs-black);
    opacity: 0.41;
    transition: 0.4s all;
    opacity: 0;
    visibility: hidden;
}

.add-employee {
    padding: 30px;
    background-color: #fff;
}

.filter-box {
    top: -15px;
    position: relative;
    z-index: 1;
}

.filter-box .accordion-item .show .accordion-body {
    margin-bottom: 10px;
    z-index: 2;
    position: relative;
}

.filter-box .accordion-item {
    border: 0;
}

.filter-box .form-floating input {
    background-color: #F8F8F8;
    border-radius: 12px;
}

.filter-box .form-floating>input:not(:placeholder-shown)~label::after,
.filter-box .form-floating>label::after {
    background-color: #F8F8F8 !important;
}

.filter-box .accordion-body {
    padding: 20px;
    border: solid 1px #707070;
    border-radius: var(--bs-border-radius);
}

.filter-box-header h3 {
    font-size: 22px;
    font-weight: 700;
}

.filter-box .form-group .custom-react-select input {
    height: 44px;
    color: var(--bs-body-color);
}

.filter-box .form-group .custom-react-select>div>div>div:first-child {
    color: var(--bs-body-color);
    opacity: 0.8;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.filter-box .form-group .custom-react-select>span+div {
    box-shadow: none !important;
    border-radius: 12px;
}

.filter-box .form-group .custom-react-select>span+div>div {
    overflow: auto;
    max-height: 65px;
}

.filter-box .form-group .custom-react-select>span+div {
    border-radius: 12px;
    background-color: #F8F8F8;
    border: 0;
    border: var(--bs-border-width) solid var(--bs-border-color);
    color: var(--bs-body-color);
}

.filter-box .btn {
    min-width: 120px;
}

.filter-box .form-group {
    margin-bottom: 15px;
}

.users-list {
    background-color: #fff;
    padding: 0 0 45px;
}

.users-list .table {
    min-width: 1100px;
}

.users-list .table tr td {
    vertical-align: middle;
    padding: 20px 25px;
    border-bottom: 2px solid #e5e5e5;
}

.user-info {
    display: flex;
    align-items: center;
}

.sort-name {
    height: 72px;
    width: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    color: #fff;
    border: 1px solid #ddd;
    overflow: hidden;
}
.sort-name img{
    object-fit: cover;
}

.greenbg {
    background-color: #35c691;
}

.purplebg {
    background-color: #6418c3;
}

.user-info-details {
    padding: 0 0 0 32px;
}

.user-info-details strong {
    display: block;
    color: #000000;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
}

.user-type {
    display: flex;
    align-items: center;
    color: #858585;
    margin: 8px 0 0 0;
}

.user-type img {
    margin: 0 10px 0 0;
}

.moreaction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.moreaction a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dddddd;
    height: 38px;
    width: 38px;
    border-radius: 12px;
    margin: 0 0 0 13px;
    transition: 0.4s all;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    -ms-transition: 0.4s all;
    -o-transition: 0.4s all;
}

.moreaction a:hover {
    background-color: #707070;
    border-color: #707070;
}

.moreaction a:hover img {
    filter: brightness(0) invert(1);
}


#adduserModal .modal-dialog {
    max-width: 667px;
}

#adduserModal .modal-dialog .modal-content {
    border-radius: 32px;
    -webkit-box-shadow: 0px 20px 20px #0662B94D;
    box-shadow: 0px 20px 20px #0662B94D;
    border: 1px solid #E5F2FF;
}

#adduserModal .btn-close {
    padding: 0;
    position: absolute;
    right: 28px;
    top: 22px;
    height: auto;
    width: auto;
    background: none;
    text-align: right;
    color: #000000;
    font-size: 14px;
    opacity: 1;
    z-index: 1;
}

#adduserModal .modal-body {
    padding: 38px 47px 55px;
}

#adduserModal .modal-title {
    color: #202020;
    font-size: 34px;
    margin: 0 0 30px;
}

.adduser-form .form-floating {
    margin: 0 0 40px;
    position: relative;
}

.adduser-form .form-floating label {
    font-size: 16px;
    color: #84818A;
    padding: 1.5rem .75rem;
    transition: opacity .1s ease-in-out, transform .3s ease-in-out;
    z-index:0;
}

.adduser-form .form-floating .form-control {
    border: 1px solid #DCDBDD;
    border-radius: 12px;
    height: 70px;
    box-shadow: none;
    font-size: 19px;
    color: #202020;
    font-weight: 500;
    padding: 23px;
    /* z-index:0; */
}

.adduser-form .form-floating .form-control::-moz-placeholder {
    color: transparent;
}

.adduser-form .form-floating .form-control::placeholder {
    color: transparent;
}

.adduser-form .form-floating select.form-control {
    background: url("../images/arrow-gray-down.svg") no-repeat;
    background-size: 18px;
    background-position: 98% center;
}

.adduser-form .form-floating>.form-control-plaintext~label,
.adduser-form .form-floating>.form-control:focus~label,
.adduser-form .form-floating>.form-control:not(:placeholder-shown)~label,
.adduser-form .form-floating>.form-select~label {
    transform: scale(.85) translateY(-2.2rem) translateX(0.15rem)
}

.adduser-form .btn.btn-fill {
    width: 100%;
    font-size: 19px;
    height: 70px;
}

.loginsso .login-box {
    width: 559px;
    max-width: 100%;
    margin: auto;
    padding: 88px 58px 108px 58px;
}

.loginsso .login-box figure {
    margin: 0 0 40px;
}

.custom-upload label{
    position: relative;
    height: 58px;
    border: solid 1px #dbdbdb;
    padding: 0 120px 0 .75rem;
    width: 100%;
    border-radius: 12px;
    cursor: pointer;
    background-color: #F8F8F8;
    display: flex;
    align-items: center;
}
.custom-upload label p{
    margin: 0;
    color: var(--bs-body-color);
    opacity: 0.8;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.custom-upload label span {
    position: absolute;
    right: 2px;
    top: 2px;
    height: 52px;
    padding: 0 20px;
    background-color: #dfdfdf;
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
}
.theme-table table.table, .theme-table table.table td, .theme-table table.table th, .theme-table table.table tr{
    border: 0;
    margin-top: 15px;
}
.theme-table table.table td{
    font-size: 14px;
    background-color: #fff;
    border-color: #f9fafd;
}

.theme-table table.table th{
    vertical-align: middle;
    padding: 20px 25px;
    border-bottom: 2px solid #eef2f9;
    background-color: #eef2f9;
}
.theme-table table.table thead tr:first-child th:first-child{
    border-top-left-radius: 10px;
}
.theme-table table.table thead tr:first-child th:last-child{
    border-top-right-radius: 10px;
}
.theme-table table.table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 10px;
}
.theme-table table.table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 10px;
}
.theme-table table.table tbody tr:last-child td{
    border-bottom: 0;
}
.theme-table table.table tbody tr:nth-child(even) td{
    background-color: #f9fafd;
}
.headingText{
    position: relative;
    padding-right: 15px;
    display: block;
    white-space: nowrap ;
}
.headingText span{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}
.pagination{
    justify-content: center;
}
.pagination li{
    margin: 0 2px;
}
.pagination li>*{
    padding: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%!important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #d6d6d6;
    color: #000;
    font-size: 14px;
}
.pagination li.active>.page-link{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.react-datepicker-wrapper{
    width: 100%;
}
.react-datepicker-popper{
    z-index: 3 !important;
}
@media(max-width:1799px) {
    .dashboard-boxes .card-body {
        padding: 1.5rem;
    }

    .small-graph {
        width: 80px;
    }

    .small-graph-content strong {
        font-size: 1.6rem;
    }

    .small-graph-content span {
        font-size: 0.875rem;
    }

    .small-graph-content {
        width: calc(100% - 80px);
    }

    .small-graph-content {
        padding-left: 0.875rem;
    }

}

@media(max-width:1599px) {
    /* .dashboard-boxes>div {
        width: 33.33%;
    } */

    .side-menu-list .accordion>li,
    .work-with,
    .side-menu-heading,
    .side-menu .side-menu-header {
        padding: 0 1.5rem;
    }

    .side-menu {
        width: 250px;
    }

    .side-menu-list ul li a {
        font-size: 1rem;
    }

    .dashboard-right {
        width: calc(100% - 250px);
        margin-left: 250px;
    }
}

@media(max-width:1499px) {
    .card-header>h5 {
        font-size: 1.225rem;
    }

    .people-list-info strong {
        font-size: 1rem;
    }

    .total-right strong,
    .card-statistics-point ul li strong {
        font-size: 1rem;
        line-height: 1;
    }

    .total-right span,
    .card-statistics-point ul li span {
        font-size: 0.875rem
    }

    .card-statistics-point ul li .dot {
        top: 0px;
    }

    .card-statistics-point ul li {
        padding-left: 1.3rem;
    }

    .card-statistics-point ul li {
        margin-right: 1.5rem;
    }

    .total-right {
        padding-left: 0.875rem
    }
}
@media(max-width:1399px){
    .dashboard-boxes>div{
        width: 50%;
    }
}
@media(max-width:1366px) {
    .card-statistics {
        flex-wrap: wrap;
    }

    .card-statistics-point {
        width: 100%;
    }

    .card-statistics-point ul {
        justify-content: space-between;
        margin-top: 15px;
    }
}

@media(max-width:1199px) {
    .login-left p {
        font-size: 1rem;
    }

    .login-box {
        padding: 2rem;
    }

    .login-box h1 {
        font-size: 2rem;
    }

    .login-box .form-floating .form-control,
    .login-box .form-floating>label {
        padding: 1.1rem .75rem;
    }

    .login-box .form-floating>.form-control-plaintext~label,
    .login-box .form-floating>.form-control:focus~label,
    .login-box .form-floating>.form-control:not(:placeholder-shown)~label,
    .login-box .form-floating>.form-select~label {
        transform: scale(.85) translateY(-2rem) translateX(0.15rem)
    }

    .login-box .btn.btn-fill {
        min-width: 10rem;
    }

    .dashboard-content {
        padding: 1.5rem 2.125rem;
    }

    .dashboard-boxes>div {
        width: 50%;
    }

    .recent-project ul li .recent-project-details strong {
        font-size: 16px;
    }
}

@media(max-width:991px) {
    .login-left {
        max-width: 100%;
    }

    .login-box h1 {
        font-size: 1.5rem;
    }

    .login-box .form-floating .form-control,
    .login-box .form-floating>label {
        font-size: 1rem;
    }

    .login-left figure img {
        height: 100px;
        width: auto;
    }

    .login-box {
        padding: 2rem 1rem;
    }

    .login-box .btn.btn-fill {
        height: 3rem;
    }

    .login-box .form-floating {
        margin-bottom: 1.5rem;
    }

    .login-box .btn.btn-fill {
        width: 100%;
        margin-bottom: 1rem;
    }

    .login-left p {
        font-size: 0.875rem;
    }

    .menu-overlay.show {
        opacity: 0.4;
        visibility: visible;
        z-index: 3;
    }

    .side-menu {
        z-index: 9;
        left: -100%;
    }

    .dashboard-right {
        width: 100%;
        margin-left: 0px;
    }

    .side-menu-open .side-menu {
        left: 0%;
        overflow: auto;
    }

    .header-profile-inner .dropdown-toggle {
        padding-right: 30px;
    }

    .profile-info {
        display: none;
    }

    .top-header {
        padding: 0.625rem 0.875rem;
    }

    .top-search input {
        height: 3rem;
    }

    .top-search .form-group span img {
        width: 18px;
        height: 18px;
    }

    .header-profile-inner .dropdown-toggle figure {
        width: 2rem;
        height: 2rem;
    }

    .notification-link {
        margin-right: 2rem;
    }

    .notification-link .badge {
        font-size: 10px;
    }

    .notification-link>a img {
        width: 24px;
        height: 24px;
    }

    .dashboard-content {
        padding: 1.5rem 0.875rem;
    }

    .dashboard-heading h1 {
        font-size: 1.5rem;
    }

    .dashboard-content .dashboard-heading p {
        font-size: 0.875rem;
    }

    .recent-project ul li .recent-project-details {
        width: 100%;
    }

    .recent-project-button {
        padding-top: 15px;
    }

    .side-menu-header {
        margin-bottom: 20px;
    }

    .work-with-cannon {
        margin-top: 1rem;
    }

    .side-menu-list {
        height: auto;
        max-height: 100%;
    }

    .btn.btn-filter[aria-expanded="true"]::after {
        height: 77px;
    }
}

@media(max-width:767px) {
    .top-header {
        flex-wrap: wrap;
    }

    .top-search {
        width: 100%;
        max-width: 100%;
        flex: 0 0 auto;
        order: 3;
        padding-top: 15px;
    }

    .menu-btn {
        order: 1;
    }

    .top-header-right {
        order: 2;
    }

    .dashboard-boxes .card-body {
        padding: 1rem;
    }

    .task-table td,
    .task-table th {
        font-size: 14px;
    }

    .task-status {
        padding-left: 1.5;
    }

    .work-with-cannon {
        padding: 1rem;
    }

    .work-with-cannon h3 {
        font-size: 14px;
    }

    .dashboard-heading {
        flex-direction: column;
    }

    .left-heading {
        width: 100%;
    }

    .right-button {
        width: 100%;
    }

    .right-button .btn.btn-filter {
        width: 50px;
    }

    .right-button .btn.btn-filter img {
        max-width: 24px;
    }

    .right-button .btn.btn-fill {
        height: 50px;
    }

    .users-list .table tr td {
        padding: 10px 15px;
    }

    .sort-name {
        height: 52px;
        width: 52px;
        font-size: 16px;
        line-height: 18px;
    }

    .user-info-details strong {
        font-size: 16px;
        line-height: 16px;
    }

    .user-type {
        font-size: 14px;
    }

    .moreaction a {
        height: 33px;
        width: 32px;
        padding: 7px;
    }

    #adduserModal .modal-title {
        font-size: 24px;
        margin: 0 0 25px;
    }

    #adduserModal .modal-dialog {
        max-width: 100%;
    }

    #adduserModal .modal-body {
        padding: 25px 20px 25px;
    }

    .adduser-form .col-6 {
        width: 100%;
    }

    .adduser-form .form-floating {
        margin: 0 0 30px;
    }

    .adduser-form .form-floating .form-control {
        font-size: 15px;
        padding: 15px;
    }

    .adduser-form .btn.btn-fill {
        font-size: 15px;
        height: 54px;
    }

    .loginsso .login-box {
        padding: 30px 30px 30px 30px;
    }

    .loginsso .login-box figure {
        margin: 0 0 20px;
    }

    .add-employee {
        padding: 15px;
    }

    .adduser-form .form-floating .form-control,
    .adduser-form .form-floating label {
        padding: 1.1rem .75rem;
        height: 58px;
    }

    .adduser-form .form-floating>.form-control-plaintext~label,
    .adduser-form .form-floating>.form-control:focus~label,
    .adduser-form .form-floating>.form-control:not(:placeholder-shown)~label,
    .adduser-form .form-floating>.form-select~label {
        transform: scale(.85) translateY(-1.95rem) translateX(0.15rem);
    }

    .filter-box .accordion-item .show .accordion-body {
        border-top-left-radius: 0;
    }

    .filter-box .accordion-body {
        padding: 15px;
    }

    .filter-box .form-floating>.form-control,
    .filter-box .form-floating>.form-control-plaintext {
        padding: 0.7rem .75rem;
        min-height: 48px;
        height: 48px;
    }

    .filter-box .form-floating>label {
        padding: 0.7rem .75rem;
    }

    .filter-box .form-group .custom-react-select input {
        height: 34px;
    }

    .filter-box .btn {
        height: 48px;
    }

    .btn.btn-filter[aria-expanded="true"]::after{
        height: 67px;
    }
}

@media(max-width:576px) {
    .dashboard-boxes>div {
        width: 100%;
    }

    .card-header {
        padding: 1em 1em 0 1em;
    }

    .card-body {
        padding: 1rem;
    }

    .recent-project ul {
        margin: 0 -0.875rem;
    }

    .recent-project ul li {
        padding: 1rem 0.875rem;
    }
}

.form-check-input:checked{
    background-color: #0662B9;
}
.notes-list li{
    padding: 10px;
    border: solid 1px #dee2e6;
    border-radius: 12px;
    background-color: #faf8f8;
    margin-bottom: 10px;
}
.notes-footer{
    padding-top: 10px;
    margin-top: 10px;
    border-top: solid 1px #dee2e6;
    display: flex;
}
.notes-footer span{
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-size: 14px;
}
.notes-body p{
    font-size: 14px;
}
.notes-body p:last-child{
    margin-bottom: 0;
}
.notes-footer span img{
    margin-right: 5px;
}


.table-count{
    font-size: 16px;
}
.table-count.form-group .custom-react-select input {
    height: 30px;
    color: var(--bs-body-color);
}

.table-count.form-group .custom-react-select>div>div>div:first-child {
    color: var(--bs-body-color);
    opacity: 0.8;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.table-count.form-group .custom-react-select>span+div {
    box-shadow: none !important;
    border-radius: 12px;
}

.table-count.form-group .custom-react-select>span+div>div {
    overflow: auto;
    max-height: 100px;
}

.table-count.form-group .custom-react-select>span+div {
    border-radius: 8px;
    background-color: #fff;
    border: 0;
    border: var(--bs-border-width) solid var(--bs-border-color);
    color: var(--bs-body-color);
}

/* 24-05-2024 */

.custom-upload{
    position: relative;
}
.custom-upload .recent-img-box{
    position: absolute;
    right: 110px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background-color: #ddd;
    border:1px solid #c5c5c5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    z-index: 1;
    border-radius: 5px;
}
.custom-upload label{
    padding: 0 170px 0 .75rem;
}
.upload-img-popup{
    position: absolute;
    top: auto;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99;
    /* border-top: 1px solid #ddd; */
    margin: auto;
    right: 0;
    bottom: 0;
    border-radius:30px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.crop-container .reactEasyCrop_Container {
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 60%;
    margin: auto;
    bottom: auto;
    top: 40px;
}
.crop-btn{
    position: absolute;
    z-index: 999;
    bottom: 56px;
    right: 0;
    width: 100%;
    width: 100% !important;
    padding: 0 47px;
}
.crop-btn .rc-slider{
    margin-bottom: 20px;
}
.crop-btn .rc-slider .rc-slider-track{
    background-color: #0662B9;
}
.crop-btn .rc-slider .rc-slider-handle{
    border-color: #0662B9;
    opacity: 1;
}
.crop-btn .rc-slider .rc-slider-handle.rc-slider-handle-dragging{
    box-shadow:0 0 0 5px rgb(6 98 185 / 40%);
    border-color: #0662B9;
}
.side-menu-list ul li a{
    position: relative;
}
.side-menu-list ul li a.collapse-nav::before{
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    right: 0;
    content: "";
    width: 20px;
    height: 20px;
    background-image: url(../images/arrow-down.svg);
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    filter: none;
}
.side-menu-list ul li a.collapse-nav:hover::before{
    filter: none !important;
}
.side-menu-list ul li a.collapse-nav.collapsed::before{
    transform: translateY(-50%) rotate(0deg);
    filter: grayscale(1) invert(1);
}
.actions{
    z-index: 0 !important;
}
.action-button{
    z-index: 0 !important;
}
.crew-modal .modal-dialog.modal-xxl{
    max-width: 1132px;
}
.crew-modal .modal-dialog.modal-xxl .modal-content{
    border-radius: 25px;
}
.related-label-fields{
    position: relative;
    margin-bottom: 25px;
    margin-top: 15px;
}
/* .related-label-fields:not(:last-child){
} */
.related-label-fields {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 30px 12px 15px;
}
.related-label-fields .related-label-head{
    position: absolute;
    top: -15px;
    left: 12px;
    background: #fff;
    padding: 0 12px;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
}
.checkout-process-strip{
    max-width: 970px;
    padding: 0;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    margin: 0 auto 40px;
}
.checkout-process-strip::before{
    position: absolute;
    content: "";
    left: 0;
    top: 67%;
    width: 100%;
    height: 1px;
    background-color: #e3e3e3;
    z-index: -1;
}
.checkout-process-strip li{
    padding: 0 15px;
    background: #fff;
}
.checkout-process-strip li p{
    margin-bottom: 5px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
}
.checkout-process-strip li.completed{
    padding-left: 0;
}
.checkout-process-strip li .process-inner-wrap{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
    background-color: #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
}
.checkout-process-strip li .process-inner-wrap span{
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    height: 100%;
    display: block;
}
.checkout-process-strip li.completed .process-inner-wrap{
    background-color:#0662b9 ;
    color: #fff;
}
.sourcebox  .rc-scrollbars-thumb{
    background-color: #cbcbcb !important;
}
.sourcebox.iron-members-sourcebox .rc-scrollbars-track , .sourcebox.crew-members-sourcebox .rc-scrollbars-track{
    width: 8px !important;
    background-color: #ededed !important;
}
.job-details-popup .add-job-group.day-shift{
    border-radius: 15px;
    background-color: #f5f5f5 !important;
    border-color: #ddd;
    /* padding: 0 !important; */
}
.job-details-popup .add-job-group.night-shift{
    border-radius: 15px;
}
.job-details-popup .add-job-group.night-shift p{
    color: #fff;
}
.job-details-popup .modal-content{
    border-radius: 15px;

}
.job-details-popup .modal-body{
    padding: 30px 20px;
}
.job-details-popup .modal-content h3{
    font-size: 20px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
    font-weight: 700;
}
.job-details-popup p{
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 8px;
}
.job-details-popup .assets{
    padding-top: 15px;
    border-top: 1px solid #ddd;
}

.sourcebox .sourcebox-listing ul li{
    position: relative;
}
.sourcebox .sourcebox-listing ul li .box-remove-btn{
    position: absolute;
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border: 1px solid #ddd;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 5px 0 0;
    /* display: none; */
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}
.sourcebox .sourcebox-listing ul li:hover .box-remove-btn{
    display: flex;
    opacity: 1;
    visibility: visible;
}
.sourcebox .sourcebox-listing ul li .box-remove-btn img{
    margin: 0;
    max-width: 17px;
}
/* .sourcebox .sourcebox-listing ul li.assets-list-wrap{

} */
.custom-checkbox {
    padding: 0 15px;
    flex: 1;
    margin: 0;
}

.custom-checkbox input {
    display: none;
}

.custom-checkbox label {
    padding: 0px 15px 0px 45px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 12px;
    background-color: #F8F8F8;
    height: 45px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-checkbox label::before {
    position: absolute;
    left: 15px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: solid 1px #ccc;
    content: '';
    top: 50%;
    transform: translateY(-50%);
}

.custom-checkbox label::after {
    position: absolute;
    left: 15px;
    width: 20px;
    height: 20px;
    content: '';
    top: 50%;
    transform: translateY(-50%) scale(0);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    background-size: 18px;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.4s all;
}

.custom-checkbox input:checked+label {
    background-color: #0256a513;
    border-color: #0256a5;
    color: #0256a5;
    font-weight: 600;
}

.custom-checkbox input:checked+label::before {
    background-color: #0256a5;
    border-color: #0256a5;
}

.custom-checkbox input:checked+label::after {
    transform: translateY(-50%) scale(1);
}

.employee-checkbox-row{
    margin-top: 15px !important;
}
.employee-checkbox-row .custom-checkbox{
    padding: 0;
    min-width: 150px;
}
.employee-checkbox-row .custom-checkbox:not(:last-child){
    padding-right: 15px;
}
.adduser-form .form-floating .react-datepicker__input-container .form-control{
    padding: 0 12px;

}
.adduser-form .form-floating .react-datepicker__input-container .form-control::placeholder{
    color: #84818A !important;
    font-weight: 400;
    font-size: 16px;
}
.comman-crop-popup{
    position: fixed;
    inset: 0;
    width: 700px;
    height:80%;
    margin: auto;
    z-index: 9;
    border: 1px solid #0256a5;
    border-radius: 30px ;
    box-shadow: rgb(2, 86, 165 , 0.2) 0px 7px 29px 0px;
}
.adduser-form .employee-submit{
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #ddd;
}
.adduser-form .employee-submit .btn.btn-fill{
    width: auto;
    height: 60px;
    font-size: 16px;
}
.adduser-form .employee-submit div{
    margin-left: 15px;
}
.crew-modal .related-label-fields-2 .subcontractors-group .form-check{
    margin: 0 !important;
    padding: 0 15px !important;
}
.crew-modal .related-label-fields-3 .subcontractors-group .form-check{
    margin: 0 !important;
    padding: 0 15px !important;
}
.crew-modal .form-group select.react-datepicker__month-select{
    height: auto;
    border-radius: 0;
}
.crew-modal .form-group select.react-datepicker__year-select{
    height: auto;
    border-radius: 0;
}
.crew-modal .form-group .react-datepicker__header__dropdown{
    margin: 8px 0;
}
.side-menu-list ul li a.collapse-nav{
    padding-right: 20px;
    display: flex;
    flex-wrap: nowrap;
}
.crew-modal .add-job-group .form-floating label{
    font-size: 16px;
    color: #84818A;
    /* padding: 1.5rem .75rem; */
    transition: opacity .1s ease-in-out, transform .3s ease-in-out;
}
.crew-modal .form-floating>.form-control:focus~label{
    transform: scale(.85) translateY(-1.8rem) translateX(.15rem);
}
.crew-modal .form-group{
    margin-bottom: 30px;
}
.crew-modal .form-group input{
    border: 1px solid #DCDBDD;
    border-radius: 12px;
}
.crew-modal .form-group select:focus, .crew-modal .form-group input:focus{
    border-color:#DCDBDD;
}
.crew-modal .form-group select:focus, .crew-modal .form-group textarea:focus{
    border-color:#DCDBDD;
    box-shadow: none;
}
.crew-modal .form-group select:focus, .crew-modal .form-group select:focus{
    border-color:#DCDBDD;
    box-shadow: none;
}
.subcontractor-modal .modal-dialog{
    max-width: 1140px !important;
}
.subcontractor-modal .adduser-form .employee-submit{
    margin-top: 20px;
    padding-top: 35px;
}


@media(max-width:767px){
    .custom-upload label{
        padding: 0 146px 0 .75rem;
    }
    .custom-upload .recent-img-box{
        right: 100px;
        width: 45px;
        padding: 5px;
    }
    #adduserModal .modal-body{
        padding: 40px 20px 25px;
    }
    .checkout-process-strip li {
        padding: 0 10px;
    }
    .checkout-process-strip li .process-inner-wrap {
        width: 30px;
        height: 30px;
    }
    .checkout-process-strip li p{
        display: none;
    }
    .checkout-process-strip li span{
        font-size: 13px;
    }
    .comman-crop-popup{
        width: auto;
        margin: auto 12px;
        height: 70%;
    }
    .comman-crop-popup .crop-btn{
        padding: 0 25px;
    }
}


/* 24-05-2024 */


/* 05-06-2024 */

.view-modal .modal-body{
    padding: 50px 47px 55px;
}
.view-modal h3 {
    color: #202020;
    font-size: 26px;
    margin-bottom: 25px;
    font-weight: 700;
}
.view-modal .btn-close {
    padding: 0;
    position: absolute;
    right: 28px;
    top: 22px;
    height: auto;
    width: auto;
    background: none;
    text-align: right;
    color: #000000;
    font-size: 14px;
    opacity: 1;
    z-index: 1;
}

.list-group-flush .row>[class*=col]{
    margin-bottom: 15px;
}
  .list-group-flush .list-group-item{
    background-color:transparent;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  /* .list-group-flush .list-group-item:nth-child(even){
    background-color: #F0F3FA;
  } */
.list-group-flush .list-group-item p{
    width: 30%;
    padding: 12px 10px 12px 12px;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;
    background-color: rgb(6, 98, 185,0.05);
    word-break: break-all;
}
.list-group-flush .list-group-item.full-view p{
    width: 100%;
}
.list-group-flush .list-group-item>div{
    width: 70%;
    padding: 12px 10px 12px 12px;
    font-weight: 500;
    color: #6d6d6d;
    font-size: 16px;
    display: block;
    text-transform: capitalize;
    border-left: 1px solid #ddd;
}
.list-group-flush .list-group-item.full-view>div{
    width: 100%;
    border-left: none;
    border-top: 1px solid #ddd;
}
.list-group-flush .list-group-item>div button{
    max-width: 20px;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;
}
.list-group-flush .list-group-item div ul{
    display: flex;
    padding-left: 0;
    margin: 0;
    gap: 5px;
    flex-wrap: wrap;
    padding-right: 0;
}
.list-group-flush .list-group-item div ul li{
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: 600;
    font-size: 10px;
    color: #01101D;
    border-radius: 5px;
    font-weight: 500;
    width: calc(50% - 2.5px);
    border: 1px solid #ddd;
    text-transform: uppercase;
    background-color: rgb(252, 148, 50);
}
.list-group-flush .list-group-item div ul li span{
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
}
.list-group-flush .list-group-item div ul li img{
    max-width: 25px;
    margin: 0 5px 0 0;
}
.view-modal .accordion-item{
    box-shadow: none;
    border: none;
}
.view-modal .accordion-item:not(:last-child){
    margin-bottom: 20px;
}
.view-modal .accordion-item .accordion-button{
    font-size: 19px;
    font-weight: 600;
    color: #fff;
    background-color: rgb(6, 98, 185,1);
    border: 1px solid #ddd;
    border-bottom: none !important;
    box-shadow: none;
    border-radius: 10px 10px 0 0 !important ;
}
.view-modal .accordion-item .accordion-button:not(.collapsed)::after{
    background-image: url(../images/minus_icon.svg);
    filter: invert(1) brightness(5);
}
.view-modal .accordion-item .accordion-button.collapsed{
    border-bottom: 1px solid #ddd !important;
    border-radius: 10px!important ;
    background-color: transparent;
    color: #000000;
}
.view-modal .accordion-item .accordion-button.collapsed::after{
    background-image: url(../images/add_plus_icon.svg);
    /* filter: invert(1) brightness(5); */
}
.view-modal .accordion-item .accordion-collapse{
    border: 1px solid #ddd;
    border-top: none !important;
    box-shadow: none;
    border-radius:0 0 10px 10px !important ;
}
.view-modal .accordion-item .accordion-body{
    padding: 30px 20px 16px;
}

.header-profile-inner .dropdown-toggle .greenbg {
    width: 3.5625rem;
    height: 3.5625rem;
    border-radius: 0.825rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    color: #fff;
}
.expend-btn{
    margin-right: 25px;
    background-color: #0662B9;
    padding: 6px 15px;
    border: 1px solid #0662B9;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    transition: all 0.2s ease-in-out;
}
.expend-btn.content-show{
    background-color: #fff;
    color: #0662B9;
    /* box-shadow: inset 0px 0 4px 0px rgb(6 98 185 / 60%); */
}
.expend-btn:hover{
    background-color: #fff;
    color: #0662B9;
}
.work-week-btn{
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #ddd;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: center;
    /* box-shadow:0px 0 4px 0px rgb(6 98 185 / 60%); */
}
.work-week-btn .toggle-button-cover {
    display: table-cell;
    position: relative;
    width: 60px;
    height: 32px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .work-week-btn .button-cover:before {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
  }

  .work-week-btn .button-cover,
  .work-week-btn .knobs,
  .work-week-btn .layer {
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .work-week-btn .button {
    position: relative;
    top: 50%;
    width: 60px;
    height: 35px;
    margin: -17px auto 0 auto;
    overflow: hidden;
  }

  .work-week-btn .button.r,
  .work-week-btn .button.r .layer {
    border-radius: 100px;
  }

  .work-week-btn .button.b2 {
    border-radius: 2px;
  }

  .work-week-btn .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .work-week-btn .knobs {
    z-index: 2;
  }

  .work-week-btn .layer {
    width: 100%;
    background-color: #cfcfcf;
    transition: 0.3s ease all;
    border: 1px solid #ddd;
    z-index: 1;
  }
.work-week-btn #button-3 .knobs:before {
    content: "Hide";
    position: absolute;
    top: 4px;
    left: 3px;
    width: 26px;
    height: 26px;
    color: #000;
    font-size: 7px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

  .work-week-btn #button-3 .checkbox:active + .knobs:before {
      width: 46px;
      border-radius: 100px;
    }

    .work-week-btn #button-3 .checkbox:checked:active + .knobs:before {
    margin-left: -26px;
}

  .work-week-btn #button-3 .checkbox:checked + .knobs:before {
      content: "Show";
      background-color: #fff;
    left: 31px;
    color: #000;
  }

  .work-week-btn #button-3 .checkbox:checked ~ .layer {
    background-color: #0662B9;
  }
.full-screen-button{
    margin-left: 15px;
    margin-right:0 !important;
}
.checkout-process-strip li.completed{
    padding-left: 15px;
}
.employee-checkbox-row .custom-checkbox{
    flex: 0 0 auto;
}
.adduser-form .custom-upload label{
    height: 70px;
}
.adduser-form .custom-upload label span{
    height: 63px;
    top: 50%;
    transform: translateY(-50%);
}
.form-check-group{
    margin-bottom: 40px;
}
.adduser-form .custom-upload label{
    padding-right: 150px;
}
.month-view .event-container .round-all.event-item .text-primary{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 0;
}
.checkbox-row.employee-checkbox-row{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.employee-checkbox-row .custom-checkbox{
    padding: 0 15px ;
}

.side-menu-open .side-menu{
    z-index: 999;
}
.equipment-edit .adduser-form .form-floating input{
    height: 76px;
}

/* 05-07-2024 */

.timeoff-date-picker .rs-picker{
    width: 100%;
}
.timeoff-date-picker .rs-picker .rs-input-group{
    background-color: #F8F8F8;
    border: 1px solid #DCDBDD;
    border-radius: 12px;
    height: 58px;
    box-shadow: none;
    font-size: 19px;
    color: #202020;
    font-weight: 500;
    padding: 8px;
}
.timeoff-date-picker .rs-picker .rs-input-group input{
    height: auto;
    padding: 0;
    background-color: #F8F8F8;
    font-size: 14px;
    font-weight: 400;

}
.table-top-row{
    align-items: center;
}
.table-top-row .form-group{
    margin-bottom: 0;
}
.table-top-row .form-group .btn{
    width: 50%;
}

@media(max-width:1199px){
    .calender-job-detail .timeline-event{
        position: relative;
    }
    .calender-mobile-filter{
        margin: 50px 0 0;
        border: 1px solid #ddd;
        border-radius: 15px;
        padding: 30px 12px 15px;
    }
    .calender-mobile-filter h3{
        font-size: 25px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 20px;
    }
    .calender-mobile-filter form .row>[class*="col"]{
        margin-bottom: 15px;
    }
    .calender-mobile-filter .form-group .form-control {
        border: 1px solid #DCDBDD;
        border-radius: 12px;
        height: 58px;
        font-size: 16px;
        font-weight: 400;
        color: #212529;
    }
    .calender-job-detail {
        margin: 30px 0;
    }
    .calender-job-detail h3{
        font-size: 23px;
        font-weight: 600;
        color: #000000;
        margin: 0 0 25px;
        text-transform: capitalize;
    }
    .calender-job-detail .event-item {
        margin: 1px 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        /* padding-right: 0 !important; */
        position: relative;
        padding: 30px 15px 20px !important;
    }

    .calender-job-detail .round-all.event-item .text-primary{
        font-size: 13px;
        line-height: 18px;
        color: #17243E !important;
        text-align: center;
        margin-bottom: 15px;
        padding: 0 10px;
        text-align: center;
        word-break: break-all;
        white-space: normal;
        font-weight: 600;
    }
    .calender-job-detail .round-all.event-item .row.crew-members>[class*="col"] div , .calender-job-detail .round-all.event-item .row.assets>[class*="col"] div {
        margin: 0 0 3px !important;
        border-radius: 5px;
        padding: 3px 5px 3px 3px;
        position: relative;
        font-size: 10px;
        min-height: 31px;
        color: #000;
        line-height: 12px;
        white-space: normal;
        height: 100%;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        position: relative;
        background-color: rgb(255, 192, 203);
    }
    .calender-job-detail .round-all.event-item .row.crew-members>[class*="col"] div img , .calender-job-detail .round-all.event-item .row.assets>[class*="col"] div img{
        max-width: 23px !important;
        max-height: 20px !important;
        border-radius: 0 !important;
        margin-right: 5px;
    }
    .calender-job-detail .round-all.event-item .row.crew-members>[class*="col"] div .box-remove-btn ,.calender-job-detail .round-all.event-item .row.assets>[class*="col"] div .box-remove-btn{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 22px;
        height: 100%;
        border-radius: 0 5px 5px 0;
        background-color: #c62826;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        transition: all 0.2s ease-in-out;
        z-index: 1;
    }
    .calender-job-detail .round-all.event-item .row.crew-members>[class*="col"] div .box-remove-btn img , .calender-job-detail .round-all.event-item .row.assets>[class*="col"] div .box-remove-btn img{
        max-width: 10px !important;
        max-height: 18px !important;
        border-radius: 0 !important;
        width: 100%;
        /* position: relative !important;
        top: 0 !important;
        left: 0 !important; */
        margin-right: 0;
        filter: invert(1) brightness(5);
    }
    .calender-job-detail .night-shift .round-all.event-item .text-primary{
        color: #fff !important;
    }
    .calender-job-detail .timeline-event:not(:last-child){
        margin-bottom: 15px;
    }
    .calender-job-detail .round-all.event-item .row>[class*="col"] {
        padding: 0 12px 8px;
    }



    .list-group-flush .list-group-item p{
        width: 100%;
    }
    .list-group-flush .list-group-item div{
        width: 100%;
        border-left: 0;
        border-top: 1px solid #ddd;
    }
}

@media(max-width:991px){
    .view-modal .modal-dialog{
        max-width: 100%;
        margin: 12px;
    }
    .view-modal .modal-body{
        padding: 38px 25px 30px;
    }
}
@media(max-width:767px){
    .view-modal h3{
        font-size: 23px;
    }
    .view-modal .modal-body {
        padding: 50px 13px 25px;
    }
    .view-modal .btn-close{
        right: 12px;
    }
    .view-modal .accordion-item .accordion-button{
        font-size: 17px;
        padding: 15px 10px;
    }
    .view-modal .accordion-item .accordion-body {
        padding: 30px 10px 15px;
    }
    .view-modal .accordion-item .accordion-button::after{
        background-size: 13px;
        background-position: center;
    }
    .list-group-flush .list-group-item p{
        font-size: 15px;
    }
    .list-group-flush .list-group-item div{
        font-size: 15px;
    }
    .adduser-form .custom-upload label{
        height: 58px;
    }
    .adduser-form .custom-upload label span {
        height: 50px;
        padding: 0 10px;
        font-size: 14px;
    }
    .adduser-form .custom-upload label{
        padding-right: 120px;
    }
    .custom-upload .recent-img-box{
        right: 72px;
    }
    .employee-checkbox-row .custom-checkbox{
        min-width: 130px;
    }
    .custom-checkbox label{
        padding: 0px 15px 0px 35px;
        font-size: 14px;
    }
    .custom-checkbox label::before{
        width: 17px;
        height: 17px;
        left: 10px;
    }
    .custom-checkbox label::after{
        left: 10px;
        width: 16px;
        height: 16px;
        background-size: 13px;
    }
    .employee-checkbox-row .custom-checkbox{
        min-width: 105px;
        margin-bottom: 10px;
    }
    .custom-form-group .custom-react-select>span+div{
        min-height: 58px;
    }
    .custom-form-group .custom-react-select>span+div>div{
        max-height: 58px !important;
        overflow: hidden !important;
        padding: 0px 8px;
    }
    .adduser-form .employee-submit .btn.btn-fill{
        height: 50px;
    }
    .adduser-form .employee-submit div .btn.btn-outline{
        height: 50px;
    }
    .adduser-form .employee-submit{
        padding-top: 30px;
    }
    .form-check-group{
        margin-bottom: 30px;
    }
    .upload-img-popup{
        height: 35%;
        border: 1px solid #ddd;
        top: 0;
        bottom: auto;
    }
    .view-modal .shifts-icon{
    position: absolute;
    top: 10px;
    left:10px;
    margin-left: 0 !important;
    }
    .table-top-row .form-group{
        display: flex;
    }
    .table-top-row .form-group .btn{
        width: 50%;
    }

}
@media(max-width:576px){
    .calender-job-detail .round-all.event-item .row{
        margin: 0 -5px;
    }
    .calender-job-detail .round-all.event-item .row>[class*="col"]{
        padding: 0 5px 8px;
    }
    .calender-job-detail .event-item{
        padding: 30px 10px 10px !important;
    }
    .calender-job-detail .timeline-event .event-item .shifts-icon{
        top: 0px;
    }
    .calender-job-detail .timeline-event .event-item .shifts-icon {
        width: auto;
        height: auto;
    }
    .header-profile-inner .dropdown-toggle .greenbg{
        width: 2.5625rem;
        height: 2.5625rem;
        font-size: 15px;
    }
}


/* loader */

.page-loader{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 85%);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.page-loader .logo-icon{
    margin-top: 50px;
}
.page-loader .logo-icon img{
    max-width: 250px;
    margin: auto;
}
.loader {
    scale: 3;
    height: 50px;
    width: 40px;
  }

  .loader-box {
    position: relative;
    opacity: 0;
    left: 10px;
  }

  .side-left {
    position: absolute;
    background-color: #286cb5;
    width: 19px;
    height: 5px;
    transform: skew(0deg, -25deg);
    top: 14px;
    left: 10px;
  }

  .side-right {
    position: absolute;
    background-color: #2f85e0;
    width: 19px;
    height: 5px;
    transform: skew(0deg, 25deg);
    top: 14px;
    left: -9px;
  }

  .side-top {
    position: absolute;
    background-color: #5fa8f5;
    width: 20px;
    height: 20px;
    rotate: 45deg;
    transform: skew(-20deg, -20deg);
  }

  .box-1 {
    animation: from-left 4s infinite;
  }

  .box-2 {
    animation: from-right 4s infinite;
    animation-delay: 1s;
  }

  .box-3 {
    animation: from-left 4s infinite;
    animation-delay: 2s;
  }

  .box-4 {
    animation: from-right 4s infinite;
    animation-delay: 3s;
  }

  @keyframes from-left {
    0% {
      z-index: 20;
      opacity: 0;
      translate: -20px -6px;
    }

    20% {
      z-index: 10;
      opacity: 1;
      translate: 0px 0px;
    }

    40% {
      z-index: 9;
      translate: 0px 4px;
    }

    60% {
      z-index: 8;
      translate: 0px 8px;
    }

    80% {
      z-index: 7;
      opacity: 1;
      translate: 0px 12px;
    }

    100% {
      z-index: 5;
      translate: 0px 30px;
      opacity: 0;
    }
  }

  @keyframes from-right {
    0% {
      z-index: 20;
      opacity: 0;
      translate: 20px -6px;
    }

    20% {
      z-index: 10;
      opacity: 1;
      translate: 0px 0px;
    }

    40% {
      z-index: 9;
      translate: 0px 4px;
    }

    60% {
      z-index: 8;
      translate: 0px 8px;
    }

    80% {
      z-index: 7;
      opacity: 1;
      translate: 0px 12px;
    }

    100% {
      z-index: 5;
      translate: 0px 30px;
      opacity: 0;
    }
  }


/* loader */

.view-modal .accordion-item .accordion-header{
    line-height: normal;
    font-size: inherit;
}
.attchment-edit{
    border: 1px solid #ddd;
    padding: 20px 10px;
    border-radius: 15px;
    background-color: #fff;
}
.attchment-edit .card-body{
    border: none;
    padding: 0;
    box-shadow: none;

}
.attchment-edit h3{
    font-size: 20px;
}
.input-with-label{
    border: 1px solid #DCDBDD;
    border-radius: 12px;
    height: 58px;
    background-color: #F8F8F8;
    font-size: 14px;
    font-weight: 400;
    color: #212529;
}
.input-with-label:hover{
    border-color: #DCDBDD !important;
}
.input-with-label .rs-input-group{
    border: none;
    padding: 0;
    background-color: transparent !important;
}
.input-with-label .rs-input-group input{
    background-color: transparent !important;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    color: #212529;
    height: 100%;
}
.input-with-label .rs-input-group:focus-within, .input-with-label .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus, .input-with-label .rs-input-group:not(.rs-input-group-disabled):hover{
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}
.table-multi-select.input-with-label{
    height: auto;
    background-color: #fff;
}
.table-multi-select .css-13cymwt-control{
    background-color: transparent;
    padding: 0;
    border: none;
    height: 100%;
}
.table-multi-select .css-t3ipsp-control{
    border-color: transparent !important;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0;
}
.dashboard-filter button.btn {
    height: 50px;
    font-size: 15px;
    font-weight: 500;
}

.employee-table-list td{
    vertical-align: top !important;
}
.employee-table-list ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
}
.employee-table-list ul li{
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    padding: 5px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}
.filter-box .form-group .custom-react-select>span+div{
    height: 70px;
    min-height: auto;
}
.filter-box .form-group .custom-react-select>span+div>div{
    font-size: 16px;
    color: #84818A;
    padding: 2px .75rem;
}
.department-cost .adduser-form .form-floating .form-control{
    font-size: 15px;
    font-weight: 500;
}
.department-cost th p{
    font-size: 15px;
    font-weight: 700;

}
.dashboard-heading {
    display: flex;
    align-items: center ;
    justify-content: space-between;
}
@media(max-width:767px){
    .heading-back .right-heading{
        width: 100%;
        margin-top: 10px;
    }
    .export-btns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }
    .export-btns a{
        margin: 0 !important;
        font-size: 14px;
        padding: 0 10px !important;
    }
    .dashboard-heading{
        align-items: start;
    }
    .dashboard-heading .dashboard-filter{
        margin-top: 15px;
        width: 100%;
    }

}
@media(max-width:576px){
    .dashboard-filter button.btn{
        width: 100%;
        margin-left: 0 !important;
        margin-bottom: 15px;
    }
    .section-head .right-button{
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .section-head .right-button .btn.btn-fill:not(:first-child){
        width: 100%;
        margin-bottom: 10px;
    }
    .section-head .right-button .btn.btn-filter {
        order: 3;
    }
}

.bg-selected-box{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    /* margin-top: 5px; */
}
.job-edit-btns:has(.text-center){
    left: 50%;
    transform: translateX(-50%);
}
p:has(.bg-selected-box){
    display: flex;
    align-items: center;
}
p:has(.bg-selected-box) .bg-selected-box{
    margin-left: 15px;
}
.login-page.loginsso .login-box{
    text-align: center;
}
.login-page.loginsso .login-box p a{
    font-size: 15px;
    font-weight: 500;
    color: #0662B9;
    width: 100%;
}
.form-group.google_field{
    position: relative;
}
.form-group.google_field .autocomplete-dropdown-container , .form-group.google_field .custom-autocomplete-suggestion{
    position: absolute;
    top:100%;
    z-index: 2;
    left: 0;
    margin-top: 3px;
    background-color: #fff;
    padding: 20px 15px;
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 10px;
    display: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.form-group.google_field .autocomplete-dropdown-container.custom-autocomplete-suggestion{

    display: block;
}
/* .form-group.google_field:has(input[aria-expanded="true"]) .autocomplete-dropdown-container{
} */
.other-login-wrap{
    padding-top: 30px;
}
.other-login-wrap a{
    font-size: 16px;
    font-weight: 500;
    /* border-bottom: 1px solid #0662B9; */
    color: #0662B9;
    text-decoration: underline !important;
    /* padding-bottom: 2px; */
}
.date-range-picker-common{
    background-color: transparent;
}
.date-picker-open .rs-picker-popup-daterange{
    z-index: 9999 !important;
}

.side-menu-list .subcategories-box .accordion-body{
    padding: 15px;
    background-color: #fff;
    margin-bottom: 15px;
}
.side-menu-list .subcategories-box .accordion-body ul li a{
    font-size: 14px;
    padding: 6px 0;
}
.subcategories-box>a{
    position: relative !important;
    padding-right: 20px !important;
}
.subcategories-box>a::before{
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    right: 0;
    content: "";
    width: 17px;
    height: 17px;
    background-image: url(../images/arrow-down.svg);
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
    filter: none;
}
.subcategories-box>a.collapsed::before{
    transform: translateY(-50%) rotate(0deg);
    filter: grayscale(1) invert(1);
}
.crew-modal .modal-body{
    padding: 38px 47px 55px;
}
.crew-modal .btn-close{
    padding: 0;
    position: absolute;
    right: 28px;
    top: 22px;
    height: auto;
    width: auto;
    background: none;
    text-align: right;
    color: #000000;
    font-size: 14px;
    opacity: 1;
    z-index: 1;
}
.crew-modal h3{
    font-size: 34px;
    font-weight: 500;
    color: #202020;
    margin-bottom: 25px;
}
.contact__info{
    position: relative;
    padding-left: 25px;
}
.contact__info img{
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 2px;
}
.checkbox-row.style-2 .form-check-inline{
    padding: 0;
}
.checkbox-row.style-2 .form-check-inline.custom-checkbox label{
    height: auto;
    border: 0;
    background: transparent;
    padding-left: 25px;
}
.checkbox-row.style-2 .form-check-inline.custom-checkbox label::before{
    left: 0;
    border-radius: 4px;
    background-color: #f5f5f5;
}
.checkbox-row.style-2 .form-check-inline.custom-checkbox input:checked+label::before{
    background-color: #0256a5;
    border-color: #0256a5;
}
.checkbox-row.style-2 .form-check-inline.custom-checkbox label::after{
    left: 0;
    border-radius: 4px;
}
@media(max-width:1599px){
    .side-menu {
        width: 310px;
    }
}
@media(max-width:991px){
    .menu-btn.side-bar-close{
        display: block;
        width: 25px;
        height: 25px;
        margin-right: 0;
    }
    .side-menu {
        width: 280px;
    }
    .side-menu-open{
    overflow: hidden;
}

}
@media (max-width: 767px) {
    .crew-modal .modal-body {
        padding: 38px 13px 25px;
    }
    .crew-modal h3 {
        font-size: 23px;
    }
}
.side-menu .rc-scrollbars-view>.accordion{
    padding-bottom: 80px;
}



.visibility-mode{
    padding: 0;
    width: 62px;
    overflow: hidden;
    background-color: transparent;
    border: none;
    transition: all 0.2s ease-in-out;
    display: flex;
}
.visibility-mode img{
    transition: all 0.2s ease-in-out;
}
.visibility-mode:hover img{
    transform: translateY(-40px);

}
.visibility-mode.active img{
    transform: translateY(-40px);
}
.visibility-mode.active:hover img{
    transform: translateY(0);
}

.input-with-label{
    height: 70px;
    font-size: 16px;

}
.input-with-label .rs-input-group {
    height: 100%;
}
.input-with-label .rs-input-group input{
    height: 100% !important;
    font-size: 16px;
}
.input-with-label .rs-input-group .rs-input-group-addon{
    height: 100% !important;
}